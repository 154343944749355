import { sendRequest } from './api'

export const getCompanyExists = (dot, token) => sendRequest('GET', `/companies/dot/${dot}/exists`, {
  headers: { authorization: `Bearer ${token}` },
})

export const createCompany = (data, token) => sendRequest('POST', '/companies', {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const getRecruiterCompany = (id, token) => sendRequest('GET', `/recruiters/${id}/company`, {
  headers: { authorization: `Bearer ${token}` },
})

export const editRecruiterCompany = (id, token, data) => sendRequest('PATCH', `recruiters/${id}/company`, {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const getCompanyGeneralInfo = (id) => sendRequest('GET', `/companies/${id}`)

export const uploadCompanyImage = (id, token, data) => sendRequest('POST', `/companies/${id}/avatar`, {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const getListOfCompanies = (companyNameSearch, token, pageNum = 1) => sendRequest('GET', '/companies', {
  query: companyNameSearch ? { companyNameSearch, pageNum } : { pageNum },
  headers: { authorization: `Bearer ${token}` },
})

export const getInvitedRecruitersOfCompany = (id, token) => sendRequest('GET', `/recruiters/${id}/company/recruiters/invite`, {
  headers: { authorization: `Bearer ${token}` },
})

export const getCompaniesFile = (token) => sendRequest('GET', '/admins/companies/csv', {
  headers: { authorization: `Bearer ${token}` },
})
