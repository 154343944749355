/* eslint-disable react/jsx-props-no-spreading */
import { useRecoilValue } from 'recoil'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import qs from 'qs'

import { ROUTE_NAMES, ROLES, ROUTE_PATH_PARAMS } from '../../config'
import { getRecruiter, getRepresentative, getSchool } from '../../api'
import {
  roleOfUserState,
  tokenState,
  currentSchoolState,
  representativeState,
  recruiterState,
} from '../../recoil/atoms'

const ProtectedRoute = ({
  component: Component, roles, checkRecruiter, checkSchoolFromUrl, defaultRedirectTo, ...rest
}) => {
  const schoolIdFromUrl = rest.computedMatch.params[ROUTE_PATH_PARAMS.SCHOOLS.ID]
  const { pathname, search } = useLocation()

  const token = useRecoilValue(tokenState)
  const userRole = useRecoilValue(roleOfUserState)
  const recruiterId = useRecoilValue(recruiterState)?.id
  const representativeId = useRecoilValue(representativeState)?.id
  const currentSchoolId = useRecoilValue(currentSchoolState)?.id
  const [recruiter, setRecruiter] = useState({
    loading: true,
    data: null,
  })
  const [representative, setRepresentative] = useState({
    loading: true,
    representative: null,
    currentSchool: null,
  })

  const shouldLoadRecruiter = token && userRole === ROLES.RECRUITER
    && checkRecruiter !== undefined

  const shouldLoadRepresentative = token && userRole === ROLES.REPRESENTATIVE
    && schoolIdFromUrl && checkSchoolFromUrl !== undefined

  useEffect(() => {
    if (shouldLoadRecruiter) {
      getRecruiter(recruiterId, token)
        .then(({ data }) => setRecruiter({ loading: false, data }))
        .catch(() => setRecruiter({ loading: false, data: null }))
    }
  }, [checkRecruiter, token, userRole, recruiterId])

  useEffect(() => {
    if (shouldLoadRepresentative) {
      Promise.all([
        getRepresentative(representativeId, token),
        getSchool(currentSchoolId, token),
      ])
        .then(([representativeResponse, currentSchoolResponse]) => {
          setRepresentative({
            loading: false,
            representative: representativeResponse.data,
            currentSchool: currentSchoolResponse.data,
          })
        })
        .catch(() => setRepresentative({
          loading: false,
          representative: null,
          currentSchool: null,
        }))
    }
  }, [checkSchoolFromUrl, token, userRole, representativeId, currentSchoolId])

  let isTokenValid = (token || roles.includes(null)) && roles.includes(userRole)

  if (userRole === ROLES.RECRUITER && checkRecruiter !== undefined) {
    if (recruiter) {
      const { status, mainRecruiter, companyId } = recruiter.data || {}

      if (checkRecruiter.status !== undefined) {
        isTokenValid = isTokenValid && status === checkRecruiter.status
      }
      if (checkRecruiter.mainRecruiter !== undefined) {
        isTokenValid = isTokenValid && mainRecruiter === checkRecruiter.mainRecruiter
      }
      if (checkRecruiter.company !== undefined) {
        isTokenValid = isTokenValid && (!!companyId) === checkRecruiter.company
      }
      if (checkRecruiter.companyId !== undefined) {
        isTokenValid = isTokenValid && companyId === checkRecruiter.companyId
      }
    } else {
      isTokenValid = false
    }
  }

  if (userRole === ROLES.REPRESENTATIVE && checkSchoolFromUrl === true) {
    if (representative.representative && representative.currentSchool) {
      isTokenValid = isTokenValid && representative.currentSchool.id === schoolIdFromUrl
    } else {
      isTokenValid = false
    }
  }

  const isLoading = (shouldLoadRecruiter && recruiter.loading)
    || (shouldLoadRepresentative && representative.loading)

  const query = qs.parse(search, { ignoreQueryPrefix: true })
  const { loginAs, ...restQueryParams } = query

  const shouldRedirectToLogin = (
    loginAs === ROLES.REPRESENTATIVE && userRole !== ROLES.REPRESENTATIVE
  ) || (
    loginAs === ROLES.RECRUITER && userRole !== ROLES.RECRUITER
  )

  const redirectTo = !shouldRedirectToLogin
    ? defaultRedirectTo || ROUTE_NAMES.HOME
    : `${ROUTE_NAMES.HOME}${qs.stringify({
      redirectAfterLoginTo: `${pathname}${qs.stringify(restQueryParams, { addQueryPrefix: true })}`,
      loginPopup: loginAs,
    }, { addQueryPrefix: true })}`

  return (
    <Route
      {...rest}
      render={
        (props) => {
          if (isLoading) {
            return null
          }
          if (isTokenValid && !shouldRedirectToLogin) {
            return <Component {...rest} {...props} />
          }
          return (
            <Redirect to={redirectTo} />
          )
        }
      }
    />
  )
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  component: PropTypes.objectOf(PropTypes.any).isRequired,
  checkRecruiter: PropTypes.objectOf(PropTypes.any),
  checkSchoolFromUrl: PropTypes.bool,
  defaultRedirectTo: PropTypes.string,
  location: PropTypes.objectOf(PropTypes.any),
}

ProtectedRoute.defaultProps = {
  defaultRedirectTo: undefined,
  location: undefined,
  checkRecruiter: undefined,
  checkSchoolFromUrl: undefined,
}
