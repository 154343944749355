import { sendRequest } from './api'

export const signupRecruiter = (body) => sendRequest('POST', '/recruiters', { body })
export const loginRecruiter = (body) => sendRequest('POST', '/recruiters/login', { body })

export const getRecruiter = (id, token) => sendRequest('GET', `/recruiters/${id}`, {
  headers: { authorization: `Bearer ${token}` },
})

export const getRecruiterList = (recruiterNameSearch, token, pageNum = 1) => sendRequest('GET', `/recruiters/`, {
    query: recruiterNameSearch ? { recruiterNameSearch, pageNum } : { pageNum },
    headers: { authorization: `Bearer ${token}` },
})

export const getRecruitersOfCompany = (id, token) => sendRequest('GET', `/recruiters/${id}/company/recruiters`, {
  headers: { authorization: `Bearer ${token}` },
})

export const signupInvitedRecruiter = (body, token) => sendRequest('POST', '/recruiters/invited/create', {
  body,
  headers: { authorization: `Bearer ${token}` },
})

export const getRecruitersFile = (token) => sendRequest('GET', '/admins/recruiters/csv', {
    headers: { authorization: `Bearer ${token}` },
})

export const approveRecruiterInCompany = (mainRecruiterId, potentialRecruiterId, token) => (
  sendRequest(
    'POST',
    `/recruiters/${mainRecruiterId}/company/recruiters/${potentialRecruiterId}/approve`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const rejectRecruiterInCompany = (mainRecruiterId, potentialRecruiterId, token) => (
  sendRequest(
    'POST',
    `/recruiters/${mainRecruiterId}/company/recruiters/${potentialRecruiterId}/reject`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const switchMainRecruiterInCompany = (oldMainId, newMainId, token) => (
  sendRequest(
    'POST',
    `/recruiters/${oldMainId}/company/recruiters/${newMainId}/main`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const editRecruiter = (id, token, data) => sendRequest('PATCH', `/recruiters/${id}`, {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const inviteRecruiter = (id, token, data) => sendRequest('POST', `/recruiters/${id}/company/recruiters/invite`, {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const changePaidStatusRecruiter = (id, token) => sendRequest('POST', `/recruiters/${id}/paid-status`, {
    headers: { authorization: `Bearer ${token}` },
})

export const getJobsOfTheCompanyByRecruiter = (id, token, pageSize, pageNum, filters) => (
  sendRequest(
    'GET',
    `/recruiters/${id}/company/jobs`,
    {
      headers: { authorization: `Bearer ${token}` },
      query: {
        ...filters,
        pageSize,
        pageNum,
      },
    },
  )
)

export const getInvitedToCompany = (id, token, companyId) => (
  sendRequest(
    'POST',
    `/recruiters/${id}/company/recruiters/invite/request/${companyId}`,
    {
      headers: { authorization: `Bearer ${token}` },
    },
  )
)
