import { sendRequest } from './api'

export const loginRepresentative = (data) => sendRequest('POST', '/representatives/login', { body: data })

export const getRepresentativeSchoolList = (token, representativeId, queryParams) => (
  sendRequest(
    'GET',
    `/representatives/${representativeId}/schools`,
    { queryParams, headers: { authorization: `Bearer ${token}` } },
  )
)

export const getRepresentative = (representativeId, token) => (
  sendRequest(
    'GET',
    `/representatives/${representativeId}`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const checkRepresentativeEmail = (email) => (
  sendRequest(
    'GET',
    `/representatives/email/${email}/exists`,
    { query: { email } },
  )
)

export const editRepresentative = (id, token, data) => sendRequest('PATCH', `/representatives/${id}`, {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})
