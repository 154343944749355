import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import qs from 'qs'

import {
  BACKEND_URL, BODY_TYPES, ERROR_NAMES, ERROR_MESSAGES, STATUS_CODES, ERROR_CODES,
} from '../config'

const mustBeUniqueReplacer = (message) => (`This ${message.replace('must be unique', 'already exists in our system')}`)

export const sendRequest = async (method, path, {
  body, query, headers, type = BODY_TYPES.JSON,
} = {}) => {
  const config = {
    method,
    headers: {},
    data: {},
    params: {},
    baseURL: BACKEND_URL,
    url: path,
  }

  if (type === BODY_TYPES.JSON) {
    config.data = body || config.data
    config.headers['Content-Type'] = BODY_TYPES.JSON
  } else if (type === BODY_TYPES.MULTIPART) {
    config.data = body || config.data
  } else if (type === BODY_TYPES.BLOB) {
    config.data = body || config.data
  }

  delete config.data['']
  delete config.data.undefined

  config.params = { ...config.params, ...query }
  config.headers = { ...config.headers, ...headers }
  config.paramsSerializer = (param) => qs.stringify(param, { arrayFormat: 'repeat' })

  return new Promise((resolve, reject) => {
    axios.request(config)
      .then((response) => {
        const { data, status, statusText } = response
        resolve({ data, status, statusText })
      })
      .catch(reject)
  })
}

export class RequestError extends Error {
  constructor(name, message, statusCode) {
    super(message)

    this.name = name
    this.statusCode = statusCode
  }

  static statusCodeToErrorNames(status) {
    if (status === STATUS_CODES.VALIDATION) return ERROR_NAMES.VALIDATION
    if (status === STATUS_CODES.UNAUTHORIZED) return ERROR_NAMES.UNAUTHORIZED
    if (status === STATUS_CODES.FORBIDDEN) return ERROR_NAMES.FORBIDDEN
    if (status === STATUS_CODES.NOT_FOUND) return ERROR_NAMES.NOT_FOUND
    if (status === STATUS_CODES.PAYMENT_REQUIRED) return ERROR_NAMES.STRIPE
    return ERROR_NAMES.INTERNAL
  }

  static fromAxiosError(err) {
    const statusCode = err.response ? err.response.status : STATUS_CODES.INTERNAL
    const code = err.response && err.response.data.code
    const name = RequestError.statusCodeToErrorNames(statusCode)
    let message = ''

    if (name === ERROR_NAMES.VALIDATION) {
      if (code === ERROR_CODES.SEQUELIZE_UNIQUE_CONSTRAINT) {
        message = err.response.data.details ? err.response.data.details.map((d) => mustBeUniqueReplacer(d.message)).join('\n') : mustBeUniqueReplacer(err.response.data.message)
      } else {
        message = err.response.data.details ? err.response.data.details.map((d) => d.message).join('\n') : err.response.data.message
      }
    } else if (name === ERROR_NAMES.UNAUTHORIZED) {
      if (code === ERROR_CODES.NOT_VALID_TOKEN) {
        message = ERROR_MESSAGES.NOT_VALID_TOKEN
      } else if (code === ERROR_CODES.NOT_VALID_CREDENTIALS) {
        message = ERROR_MESSAGES.NOT_VALID_CREDENTIALS
      } else {
        message = ERROR_MESSAGES.UNAUTHORIZED
      }
    } else if (name === ERROR_NAMES.FORBIDDEN) {
      message = ERROR_MESSAGES.FORBIDDEN
    } else if (name === ERROR_NAMES.NOT_FOUND) {
      message = err.response.data.message
    } else if (name === ERROR_NAMES.STRIPE) {
      message = err.response.data.message
    } else {
      message = ERROR_MESSAGES.INTERNAL
    }

    return new RequestError(name, message, statusCode)
  }
}

export const handleRequestError = (err, showError = true) => {
  const requestError = RequestError.fromAxiosError(err)
  if (showError) NotificationManager.error('Request error', requestError.message, 5000)
  return requestError
}
