import { sendRequest } from './api'

export const detachPaymentMethod = (token, recruiterId) => sendRequest('DELETE', `/recruiters/${recruiterId}/payment-method`, {
  headers: { authorization: `Bearer ${token}` },
})

export const getPaymentMethod = (token, recruiterId) => sendRequest('GET', `/recruiters/${recruiterId}/payment-method`, {
  headers: { authorization: `Bearer ${token}` },
})

export const setupIntents = (token, recruiterId) => sendRequest('POST', `/recruiters/${recruiterId}/setup-intents`, {
  headers: { authorization: `Bearer ${token}` },
})
