import { schoolSchema, jobSchema } from '@job-board/job-board-validation'

const inputs = {
  name: {
    type: 'text',
    className: 'patch-school_input',
    label: 'School Name',
    placeholder: 'School name',
  },
  url: {
    type: 'text',
    className: 'patch-school_input',
    label: 'School Web Page',
    placeholder: 'School Web Page URL',
  },
  email: {
    type: 'email',
    className: 'patch-school_input',
    label: 'School E-mail',
    placeholder: 'School e-mail',
  },
  phone: {
    isNested: true,
    type: 'phone-number',
    className: 'patch-school_input',
    label: 'School Phone Number',
    placeholder: '356-256-2525',
  },
  street: {
    type: 'text',
    className: 'sign-up-school-page_input',
    label: 'School Street Address',
    placeholder: 'School Street Address',
  },
  city: {
    type: 'text',
    className: 'sign-up-school-page_input',
    label: 'School City',
    placeholder: 'School City',
  },
  state: {
    isNested: true,
    type: 'search-select',
    className: 'sign-up-school-page_input',
    name: 'state',
    label: 'School State',
    placeholder: 'Choose school State',
    options: jobSchema.allowedValues.states.map((s) => ({
      label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
    })),
    isMulti: false,
  },
  zip: {
    type: 'text',
    className: 'patch-school_input',
    label: 'Zip',
    placeholder: 'Zip',
  },
}

const schema = schoolSchema.patchSchool.front

// eslint-disable-next-line import/prefer-default-export
export const patchSchoolConfig = {
  inputs,
  schema,
}
