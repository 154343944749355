import { sendRequest } from './api'

export const listJobs = (query) => sendRequest('GET', '/jobs', { query })

export const getJob = (id, token) => sendRequest('GET', `/jobs/${id}`, {
  headers: { authorization: `Bearer ${token}` },
})

export const deleteJobById = (id, token) => sendRequest('DELETE', `/jobs/${id}`, {
  headers: { authorization: `Bearer ${token}` },
})

export const getJobsOfTheCompany = (companyId) => sendRequest('GET', `/jobs/?companyId=${companyId}`)

export const deleteJobGroup = (id, token) => sendRequest('DELETE', `/job-groups/${id}`, {
  headers: { authorization: `Bearer ${token}` },
})

export const createJobGroup = (data, token) => sendRequest('POST', '/job-groups', {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const createJobTemplate = (data, token) => sendRequest('POST', '/templates', { body: data, headers: { authorization: `Bearer ${token}` } })

export const editJob = (id, token, data) => sendRequest('PATCH', `jobs/${id}`, {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const getReportsPending = (token, pageSize, pageNum) => sendRequest(
  'GET',
  '/reports?status=Pending',
  {
    headers: { authorization: `Bearer ${token}` },
    query: {
      pageSize,
      pageNum,
    },
  },
)

export const getReport = (id, token) => sendRequest('GET', `/reports/${id}`, {
  headers: { authorization: `Bearer ${token}` },
})

export const resolveReport = (id, token) => sendRequest('POST', `/reports/${id}/resolve`, {
  headers: { authorization: `Bearer ${token}` },
})

export const repostedJob = (id, token) => sendRequest('POST', `/jobs/${id}/repost`, {
  headers: { authorization: `Bearer ${token}` },
})

export const createReport = (jobId, schoolId, description, token) => sendRequest('POST', `/schools/${schoolId}/jobs/${jobId}/report`, {
  body: description,
  headers: { authorization: `Bearer ${token}` },
})

export const createTemplateFromExistingJob = (jobId, templateName, token) => sendRequest('POST', `/templates/from/jobs/${jobId}`, {
  body: templateName,
  headers: { authorization: `Bearer ${token}` },
})

export const listJobTemplates = (token) => sendRequest('GET', '/templates', {
  headers: { authorization: `Bearer ${token}` },
  query: {
    pageSize: 9999999,
  },
})

export const payForUnpaidJobs = (token) => sendRequest('PATCH', '/jobs/unpaid/pay', {
  headers: { authorization: `Bearer ${token}` },
})

export const getAmountForUnpaidJobs = (token) => sendRequest('GET', '/jobs/unpaid/amount', {
  headers: { authorization: `Bearer ${token}` },
})
