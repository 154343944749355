import Helmet from 'react-helmet'
import { useLocation } from 'react-router'
import React, { useEffect, useState } from 'react'

const BasicHead = () => {
  const { pathname } = useLocation()

  const [title, setTitle] = useState('Truck School Job Board')

  const currentTitle = {
    '/': 'Get Professional Online Job Board for your Drivers with TruckSchoolJobBoard.com',
    '/privacy-notice': 'Privacy Notice',
    '/terms-and-conditions': 'Terms and Conditions',
    '/school/sign-up': 'Sign Up Your Trucking School',
    '/recruiter/sign-up': 'Setup Your Recruiter Account and Start recruiting new drivers directly!',
    '/forgot-password': 'Forgot Your Password?',
    '/school/search': 'Find a School’s Job Board',
    '/school/claim': 'Claim your school',
  }[
    pathname.length > 1 && pathname.endsWith('/')
      ? pathname.slice(0, -1)
      : pathname
  ]

  useEffect(() => {
    setTitle(currentTitle || 'Truck School Job Board')
  }, [pathname])

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export default BasicHead
