import { sendRequest } from './api'

export const listReferrals = (token, query, pageNum = 1, pageSize = 10) => sendRequest('GET', '/referral', {
  query: { ...query, pageNum, pageSize },
  headers: { authorization: `Bearer ${token}` },
})

export const listReferralsRecruiter = (token, id, query, pageNum = 1, pageSize = 10) => sendRequest('GET', `/referral/recruiter/${id}`, {
  query: { ...query, pageNum, pageSize },
  headers: { authorization: `Bearer ${token}` },
})

export const createReferral = (token, data) => sendRequest('POST', '/referral/', {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const deactivateReferral = (token, id) => sendRequest('PATCH', `/referral/deactivate/${id}`, {
  headers: { authorization: `Bearer ${token}` },
})

export const referralExists = (code) => sendRequest('GET', `/referral/exists/${code}`)
