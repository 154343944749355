import { recruiterSchema } from '@job-board/job-board-validation'

const inputs = {
  name: {
    type: 'text',
    className: 'sign-up-recruiter-page_input',
    label: 'Name',
    placeholder: 'Enter Recruiter Name',
  },
  email: {
    type: 'email',
    className: 'sign-up-recruiter-page_input',
    label: 'E-mail',
    placeholder: 'Enter E-mail',
  },
  phone: {
    isNested: true,
    type: 'phone-number',
    className: 'sign-up-recruiter-page_input',
    label: 'Phone',
    placeholder: 'Enter phone',
  },
  initialCompanyName: {
    type: 'text',
    className: 'sign-up-recruiter-page_input',
    label: 'Company Name',
    placeholder: 'Enter Company Name',
  },
  initialCompanyDot: {
    type: 'text',
    className: 'sign-up-recruiter-page_input',
    label: 'Company DOT #',
    placeholder: 'Enter Company DOT Number',
  },
  password: {
    type: 'password',
    className: 'sign-up-recruiter-page_input',
    label: 'Password',
    placeholder: 'Create Password',
  },
}

const initialValues = {
  name: '',
  email: '',
  phone: '',
  initialCompanyName: '',
  initialCompanyDot: '',
  password: '',
}

const schema = recruiterSchema.createRecruiter.front
/* eslint-disable import/prefer-default-export */
export const signUpRecruiterFormConfig = {
  schema,
  initialValues,
  inputs,
}
