import React, { useEffect, Suspense } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { NotificationContainer } from 'react-notifications'
import {
  BrowserRouter as Router, Route, Switch, useLocation,
} from 'react-router-dom'

import {
  ROUTE_NAMES, ROLES, RECRUITER_STATUSES, DOMAIN,
} from '../config'
import {
    roleOfUserState, tokenState, recruiterState, representativeState, currentSchoolState, adminState, studentState,
} from '../recoil/atoms'
import ProtectedRoute from '../components/common/ProtectedRoute'

import '../styles/index.scss'
import updateUserData from '../helpers/userData/updateUserData'
import getDomainInfo from '../helpers/domain'
import BasicHead from '../components/helmet/helmet'

const HomePage = React.lazy(() => import('./homePage'))
const PrivacyNotice = React.lazy(() => import('./PrivacyNotice'))
const TermsAndConditions = React.lazy(() => import('./TermsAndCondition'))
const NotFound = React.lazy(() => import('./notFound'))
const ForgotPasswordPage = React.lazy(() => import('./forgotPasswordPage'))
const ResetPasswordPage = React.lazy(() => import('./resetPasswordPage'))

const NewJob = React.lazy(() => import('./job/newJob'))
const JobView = React.lazy(() => import('./job/jobView'))
const JobsList = React.lazy(() => import('./job/jobsList'))

const ApplicationPage = React.lazy(() => import('./application/applicationPage'))
const ApplicationDriverSettingPage = React.lazy(() => import('./application/applicationDriverSettingPage'))

const SignUpSchool = React.lazy(() => import('./school/signUpSchool'))
const EditSchool = React.lazy(() => import('./school/editSchool'))
const ClaimSchool = React.lazy(() => import('./school/claimSchool'))
const SchoolSettings = React.lazy(() => import('./school/schoolSettings'))
const SchoolSearch = React.lazy(() => import('./school/schoolSearch'))
const EditRepresentative = React.lazy(() => import('./school/editRepresentative'))

const CompanySetUp = React.lazy(() => import('./company/companySetUp'))
const CompanySettings = React.lazy(() => import('./company/companySettings'))

const EditRecruiter = React.lazy(() => import('./recruiter/editRecruiter'))
const RecruiterPaymentMethod = React.lazy(() => import('./recruiter/RecruiterPaymentMethod'))
const SignUpRecruiter = React.lazy(() => import('./recruiter/signUpRecruiter'))
const SignUpStudent = React.lazy(() => import('./student/signUpStudent'))
const StudentSettings = React.lazy(() => import('./student/settings'))
const SignUpInvitedRecruiter = React.lazy(() => import('./recruiter/signUpInvitedRecruiter'))
const RecruitersJobList = React.lazy(() => import('./recruiter/recruitersJobList'))
const RecruiterSettings = React.lazy(() => import('./recruiter/recruiterSettings'))

const StudentApplications =  React.lazy(() => import('./student/studentApplications'))
const RecruterApplications =  React.lazy(() => import('./recruiter/recruterApplications'))

const LogInAdmin = React.lazy(() => import('./admin/logInAdmin'))
const ImportSchool = React.lazy(() => import('./admin/importSchool'))
const AdminReports = React.lazy(() => import('./admin/adminReports'))
const SchoolsForAdmin = React.lazy(() => import('./admin/schoolsForAdmin'))
const SchoolsSearch = React.lazy(() => import('./school/schoolSearch'))
const AdminSettings = React.lazy(() => import('./admin/adminSettings'))
const CompaniesForAdmin = React.lazy(() => import('./admin/companiesForAdmin'))
const Company = React.lazy(() => import('./admin/company'))
const Recruiters = React.lazy(() => import('./admin/recruitersForAdmin'))

// useLocation can not be in same component as Router
const LocationChecker = () => {
  const location = useLocation()

  const [token, setToken] = useRecoilState(tokenState)

  const setRoleInfo = useSetRecoilState(roleOfUserState)
  const [, setRecruiterStateInfo] = useRecoilState(recruiterState)
  const [, setRepresentativeStateInfo] = useRecoilState(representativeState)
  const [, setStudent] = useRecoilState(studentState)
  const [currentSchoolStateInfo, setCurrentSchoolStateInfo] = useRecoilState(currentSchoolState)
  const [, setAdminStateInfo] = useRecoilState(adminState)

  useEffect(() => {
    updateUserData({
      token,
      currentSchool: currentSchoolStateInfo,
      setRoleInfo,
      setRecruiterStateInfo,
      setRepresentativeStateInfo,
      setAdminStateInfo,
      setCurrentSchoolStateInfo,
      setToken,
      setStudent,
    })
  }, [location])
  return null
}

const mainRoutes = (
  <Switch>
    <Route exact path={ROUTE_NAMES.HOME} component={HomePage} />
    <Route exact path={ROUTE_NAMES.PRIVACY_NOTICE} component={PrivacyNotice} />
    <Route exact path={ROUTE_NAMES.TERMS_AND_CONDITIONS} component={TermsAndConditions} />
    <Route exact path={ROUTE_NAMES.FORGOT_PASSWORD} component={ForgotPasswordPage} />
    <Route exact path={ROUTE_NAMES.RESET_PASSWORD} component={ResetPasswordPage} />

    {/* STUDENT */}
    <ProtectedRoute
        roles={[ROLES.STUDENT]}
        path={ROUTE_NAMES.STUDENT.APPLICATIONS}
        component={StudentApplications}
    />
    <Route
        path={ROUTE_NAMES.STUDENT.SIGN_UP}
        component={SignUpStudent}
    />
    <ProtectedRoute
        roles={[ROLES.STUDENT]}
        path={ROUTE_NAMES.STUDENT.SETTINGS}
        component={StudentSettings}
    />
      <ProtectedRoute
          roles={[ROLES.STUDENT]}
          path={ROUTE_NAMES.STUDENT.APPLICATION_FORM_EDIT}
          component={ApplicationDriverSettingPage}
          exact
      />

    {/* RECRUITER */}

    <Route
        path={ROUTE_NAMES.RECRUITER.SIGN_UP}
        component={SignUpRecruiter}
    />
    <ProtectedRoute
        path={ROUTE_NAMES.RECRUITER.APPLICATIONS}
        roles={[ROLES.RECRUITER]}
        component={RecruterApplications}
    />
    <Route
      path={ROUTE_NAMES.RECRUITER.SIGN_UP_INVITED}
      component={SignUpInvitedRecruiter}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.RECRUITER.EDIT}
      roles={[ROLES.RECRUITER]}
      component={EditRecruiter}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.RECRUITER.PAYMENT_METHOD}
      roles={[ROLES.RECRUITER]}
      component={RecruiterPaymentMethod}
    />

    <ProtectedRoute
      path={ROUTE_NAMES.RECRUITER.JOBS}
      roles={[ROLES.RECRUITER]}
      component={RecruitersJobList}
      checkRecruiter={{ company: true, status: RECRUITER_STATUSES.APPROVED }}
    />

    <ProtectedRoute
      path={ROUTE_NAMES.RECRUITER.SETTINGS}
      roles={[ROLES.RECRUITER]}
      component={RecruiterSettings}
      checkRecruiter={{ mainRecruiter: false }}
    />

    {/* SCHOOL */}

    <ProtectedRoute
      path={ROUTE_NAMES.SCHOOL.SIGN_UP}
      roles={[ROLES.REPRESENTATIVE, null]}
      component={SignUpSchool}
    />
    <ProtectedRoute
        path={ROUTE_NAMES.SCHOOL.SCHOOLS_LIST}
        roles={[ROLES.ADMIN, ROLES.RECRUITER, ROLES.STUDENT]}
        component={SchoolsSearch}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.SCHOOL.CLAIM}
      roles={[ROLES.REPRESENTATIVE, null]}
      component={ClaimSchool}
    />
    <Route
      path={ROUTE_NAMES.SCHOOL.SEARCH}
      component={SchoolSearch}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.SCHOOL.EDIT}
      roles={[ROLES.REPRESENTATIVE, ROLES.ADMIN]}
      checkSchoolFromUrl
      component={EditSchool}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.SCHOOL.SETTINGS}
      roles={[ROLES.REPRESENTATIVE]}
      component={SchoolSettings}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.REPRESENTATIVE.EDIT}
      roles={[ROLES.REPRESENTATIVE]}
      component={EditRepresentative}
    />

    {/* JOB */}

    <Route
        path={ROUTE_NAMES.APPLICATION.DOMAIN}
        component={ApplicationPage}
        roles={[ROLES.STUDENT]}
        exact
    />

    <ProtectedRoute
      path={ROUTE_NAMES.JOBS.NEW}
      roles={[ROLES.RECRUITER]}
      component={NewJob}
      checkRecruiter={{ company: true }}
    />

    {/* SCHOOL BOARD */}

    <Route exact path={ROUTE_NAMES.SCHOOL.BOARD} component={JobsList} />
    <Route exact path={ROUTE_NAMES.SCHOOL.BOARD_JOB} component={JobView} />

    {/* COMPANY */}

    <ProtectedRoute
      path={ROUTE_NAMES.COMPANY.SET_UP}
      roles={[ROLES.RECRUITER]}
      component={CompanySetUp}
      checkRecruiter={{ company: false }}
      defaultRedirectTo={ROUTE_NAMES.COMPANY.EDIT}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.COMPANY.SETTINGS}
      roles={[ROLES.RECRUITER]}
      component={CompanySettings}
      checkRecruiter={{ mainRecruiter: true }}
    />

    {/* ADMIN */}

    <Route
      path={ROUTE_NAMES.ADMIN.LOGIN}
      component={LogInAdmin}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.ADMIN.REPORTS}
      roles={[ROLES.ADMIN]}
      component={AdminReports}
    />

    <ProtectedRoute
      path={ROUTE_NAMES.ADMIN.SCHOOL.SETTINGS}
      roles={[ROLES.REPRESENTATIVE, ROLES.ADMIN]}
      component={SchoolSettings}
    />

    <ProtectedRoute
      path={ROUTE_NAMES.ADMIN.IMPORT}
      roles={[ROLES.ADMIN]}
      component={ImportSchool}
      defaultRedirectTo={ROUTE_NAMES.ADMIN.LOGIN}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.ADMIN.SCHOOLS_LIST}
      roles={[ROLES.ADMIN]}
      component={SchoolsForAdmin}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.ADMIN.SETTINGS}
      roles={[ROLES.ADMIN]}
      component={AdminSettings}
    />
    <ProtectedRoute
      path={ROUTE_NAMES.ADMIN.COMPANIES_LIST}
      roles={[ROLES.ADMIN]}
      component={CompaniesForAdmin}
    />
    <ProtectedRoute
      roles={[ROLES.ADMIN]}
      path={ROUTE_NAMES.ADMIN.COMPANY}
      component={Company}
    />
    <ProtectedRoute
      roles={[ROLES.ADMIN]}
      path={ROUTE_NAMES.ADMIN.RECRUITERS}
      component={Recruiters}
    />

    <Route path="*" component={NotFound} />
  </Switch>
)

const subdomainRoutes = (
  <Switch>
    {/* JOB */}

    <Route
        path={ROUTE_NAMES.APPLICATION.SUB_DOMAIN}
        component={ApplicationPage}
        roles={[ROLES.STUDENT]}
        exact
    />

    {/* SCHOOL BOARD */}

    <Route exact path={ROUTE_NAMES.SCHOOL.SUB_DOMAIN_BOARD} component={JobsList} />
    <Route exact path={ROUTE_NAMES.SCHOOL.SUB_DOMAIN_BOARD_JOB} component={JobView} />

    <Route
        path={ROUTE_NAMES.NOT_FOUND}
        render={(props) => (
            <NotFound mainText={'School not found !'} {...props} />
        )}
    />

    <Route path="*" component={NotFound} />
  </Switch>
)

const getRoutes = () => {
  const { domain, subdomain } = getDomainInfo(window.location.host)

  if (DOMAIN && domain !== DOMAIN && subdomain) return subdomainRoutes

  return mainRoutes
}

function App() {
  return (
    <>
      <Suspense fallback={<div> </div>}>
        <Router>
          <BasicHead />
          {getRoutes()}
          <LocationChecker />
          <NotificationContainer />
        </Router>
      </Suspense>
    </>
  )
}

export default App
