import { schoolSchema } from '@job-board/job-board-validation'

/* eslint-disable import/prefer-default-export */
export const importSchoolConfig = {
  schema: schoolSchema.importSchools.front,
  initialValues: {
    schools: '',
  },
  inputs: {
    schools: {
      type: 'file',
      className: 'input',
      placeholder: 'Schools CSV',
    },
  },
}
