import { adminCommonSchema } from '@job-board/job-board-validation'

const initialValues = {
  jobPrice: 5,
}

const inputs = {
  jobPrice: {
    type: 'price-input',
    placement: 'nested_input',
    className: 'input--no-arrows',
    labelStart: 'Job price: $',
  },
}

const schema = adminCommonSchema.patchJobPrice.fromt
/* eslint-disable import/prefer-default-export */
export const jobPriceSettingsConfig = {
  initialValues,
  schema,
  inputs,
}
