import { schoolSchema } from '@job-board/job-board-validation'

export const claimSchoolsAndCreateRepresentativeFormConfig = {
  schema: schoolSchema.claimSchoolsAndCreateRepresentative.front,
  initialValues: {
    representative: {
      name: '',
      email: '',
      password: '',
    },
    codeToUseOnActivation: '',
    schoolsIds: [],
  },
  inputs: {
    representativeName: {
      type: 'text',
      name: 'representative.name',
      className: 'claim-school-page_input',
      label: 'School Representative Name',
      placeholder: 'School representative name',
    },
    representativeEmail: {
      type: 'email',
      name: 'representative.email',
      className: 'claim-school-page_input',
      label: 'School Representative E-mail',
      placeholder: 'School representative e-mail',
    },
    password: {
      type: 'password',
      name: 'representative.password',
      className: 'claim-school-page_input',
      label: 'Password',
      placeholder: 'Create password',
    },
    codeToUseOnActivation: {
      type: 'text',
      name: 'codeToUseOnActivation',
      className: 'claim-school-page_input',
      label: 'Referral code',
      placeholder: '',
    },
  },
}

export const claimSchoolsFormConfig = {
  schema: schoolSchema.claimSchools.front,
  initialValues: {
    schoolsIds: [],
    codeToUseOnActivation: '',
  },
  inputs: {
    codeToUseOnActivation: {
      type: 'text',
      name: 'codeToUseOnActivation',
      className: 'claim-school-page_input',
      label: 'Referral code',
      placeholder: '',
    },
  },
}
