import cloneDeep from 'lodash/cloneDeep'
import { schoolSchema, jobSchema } from '@job-board/job-board-validation'

export const signUpSchoolInputs = [
  {
    representativeName: {
      type: 'text',
      name: 'representative.name',
      className: 'sign-up-school-page_input',
      label: 'School Representative Name',
      placeholder: 'School representative name',
    },
    representativeEmail: {
      type: 'email',
      name: 'representative.email',
      className: 'sign-up-school-page_input',
      label: 'School Representative E-mail',
      placeholder: 'School representative e-mail',
    },
    password: {
      type: 'password',
      name: 'representative.password',
      className: 'sign-up-school-page_input',
      label: 'Password',
      placeholder: 'Create password',
    },
  },
  {
    name: {
      type: 'text',
      name: 'school.name',
      className: 'sign-up-school-page_input',
      label: 'School Name',
      placeholder: 'School name',
    },
    url: {
      type: 'text',
      name: 'school.url',
      className: 'sign-up-school-page_input',
      label: 'School Web Page',
      placeholder: 'School Web Page URL',
    },
    email: {
      type: 'email',
      name: 'school.email',
      className: 'sign-up-school-page_input',
      label: 'School E-mail',
      placeholder: 'School e-mail',
    },
    phone: {
      isNested: true,
      type: 'phone-number',
      name: 'school.phone',
      className: 'sign-up-school-page_input',
      label: 'School Phone Number',
      placeholder: '356-256-2525',
    },
    codeToUseOnActivation: {
      type: 'text',
      name: 'codeToUseOnActivation',
      className: 'sign-up-school-page_input',
      label: 'Referral code',
      placeholder: '',
    },
  },
  {
    street: {
      type: 'text',
      name: 'school.street',
      className: 'sign-up-school-page_input',
      label: 'School Street Address',
      placeholder: 'School Street Address',
    },
    city: {
      type: 'text',
      name: 'school.city',
      className: 'sign-up-school-page_input',
      label: 'School City',
      placeholder: 'School City',
    },
    state: {
      isNested: true,
      type: 'search-select',
      name: 'school.state',
      label: 'School State',
      options: jobSchema.allowedValues.states.map((s) => ({
        label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
      })),
      isMulti: false,
      placeholder: 'State',
    },
    zip: {
      type: 'text',
      name: 'school.zip',
      className: 'sign-up-school-page_input',
      label: 'School Zip Code',
      placeholder: 'School Zip Code',
    },
  },
]

const initialValues = [
  {
    representative: {
      name: '',
      email: '',
      password: '',
    },
  }, {
    school: {
      name: '',
      url: '',
      email: '',
      phone: '',
    },
    codeToUseOnActivation: '',
  }, {
    school: {
      state: '',
      city: '',
      zip: '',
      street: '',
    },
  },
]

export const signUpSchoolConfig = signUpSchoolInputs.map((pageInputs, i) => ({
  inputs: pageInputs,
  initialValues: cloneDeep(initialValues[i]),
  schema: schoolSchema.createClaimedSchool.front[i],
  key: i,
}))
