import { companySchema, jobSchema } from '@job-board/job-board-validation'
import allowedValues from '@job-board/job-board-validation/company/allowed.values'
/* eslint-disable import/prefer-default-export */
export const companySetUpConfig = [
  {
    initialValues: {
      name: '',
      dot: '',
      addressHQ: {
        zip: '',
        city: '',
        state: '',
        street: '',
      },
      url: '',
    },
    inputs: {
      name: {
        type: 'text',
        className: 'input input--small',
        label: 'Company Name',
        placeholder: 'Type company name here',
        placement: 'columns',
        placementClass: 'company-field-no-spaces',
      },
      dot: {
        type: 'text',
        className: 'input input--small',
        label: 'DOT #',
        placeholder: 'Please provide Company DOT Number',
        placement: 'columns',
        placementClass: 'company-field-no-spaces',
      },
      addressHQ: {
        isNested: true,
        label: 'Company HQ Address',
        placement: 'columns',
        street: {
          type: 'text',
          name: 'addressHQ.street',
          className: 'input input--large',
          placeholder: 'Street address',
        },
        city: {
          type: 'text',
          name: 'addressHQ.city',
          className: 'input input--large',
          placeholder: 'City',
        },
        state: {
          isNested: true,
          type: 'search-select',
          name: 'addressHQ.state',
          className: 'input input--large',
          options: jobSchema.allowedValues.states.map((s) => ({
            label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
          })),
          isMulti: false,
          placeholder: 'State',
        },
        zip: {
          type: 'text',
          name: 'addressHQ.zip',
          className: 'input input--large',
          placeholder: 'Zip',
        },
      },
      url: {
        type: 'text',
        className: 'input input--small',
        label: 'Company Web Page',
        placeholder: 'Company Web Page URL',
        placement: 'columns',
        placementClass: 'company-field-no-spaces',
      },
    },
  },
  {
    initialValues: {
      locationQuantity: '',
      employeesQuantity: '',
      CDLDriversQuantity: '',
    },
    inputs: {
      locationQuantity: {
        isNested: true,
        label: 'Number of locations',
        type: 'radio',
        name: 'locationQuantity',
        options: companySchema.allowedValues.locationQuantity,
        placement: 'flex',
      },
      employeesQuantity: {
        isNested: true,
        label: 'Number of total employees',
        type: 'radio',
        name: 'employeesQuantity',
        options: companySchema.allowedValues.employeesQuantity,
        placement: 'flex',
      },
      CDLDriversQuantity: {
        isNested: true,
        label: 'Number of CDL drivers ',
        type: 'radio',
        name: 'CDLDriversQuantity',
        options: companySchema.allowedValues.CDLDriversQuantity,
        placement: 'flex',
      },
    },
  },
  {
    initialValues: {
      formalTrainingProcess: '',

      hireWithExperience: '',
      minimumDUITime: null,
      maximumDUIInLife: null,

      hireWithFelonies: '',

      makeOfTractors: [],
    },
    inputs: {
      formalTrainingProcess: {
        isNested: true,
        label: 'Does your company have a formal training process for entry level drivers?',
        type: 'booleanRadio',
        name: 'formalTrainingProcess',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        placement: 'flex',
      },

      hireWithExperience: {
        isNested: true,
        label: 'Does your company hire drivers with previous DUI(s)?',
        type: 'booleanRadio',
        name: 'hireWithExperience',
        options: [
          { value: true, title: 'Yes' },
          {
            minimumDUITime: {
              isNested: true,
              labelStart: 'DUI must be a minimum of ',
              labelEnd: ' years old',
              type: 'number-select',
              name: 'minimumDUITime',
              placeholder: '-',
              min: 0,
              max: 25,
              dependence: ['hireWithExperience', true],
              placement: 'nested_input',
            },
            maximumDUIInLife: {
              isNested: true,
              labelStart: 'No more than ',
              labelEnd: ' DUIs in a lifetime',
              type: 'number-select',
              name: 'maximumDUIInLife',
              placeholder: '-',
              min: 0,
              max: 25,
              dependence: ['hireWithExperience', true],
              placement: 'nested_input',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'minimumDUITime', value: '' },
          { if: true, field: 'maximumDUIInLife', value: '' },
          { if: false, field: 'minimumDUITime', value: null },
          { if: false, field: 'maximumDUIInLife', value: null },
        ],
        placement: 'flex',
      },

      hireWithFelonies: {
        isNested: true,
        label: 'Does your company hire drivers with certain types of felonies?',
        type: 'booleanRadio',
        name: 'hireWithFelonies',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        placement: 'flex',
      },

      makeOfTractors: {
        isNested: true,
        label: 'Make of tractors',
        type: 'checkbox',
        name: 'makeOfTractors',
        className: 'input_make-of-tractors',
        options: companySchema.allowedValues.makeOfTractors,
        placement: 'flex',
      },
    },
  },
  {
    initialValues: {
      union: '',
      healthInsurance: '',
      healthInsuranceForEmployee: '',
      healthInsuranceForFamily: '',
      healthInsuranceEligableAfter: '',
    },
    inputs: {
      union: {
        isNested: true,
        label: 'Union?',
        type: 'booleanRadio',
        name: 'union',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        placement: 'columns',
      },
      healthInsurance: {
        isNested: true,
        label: 'Company paid health insurance?',
        type: 'booleanRadio',
        name: 'healthInsurance',
        options: [
          { value: true, title: 'Yes' },
          {
            healthInsuranceForEmployee: {
              isNested: true,
              label: 'Company paid for employee',
              type: 'radio',
              name: 'healthInsuranceForEmployee',
              options: ['Full', 'Partial', 'None'],
              dependence: ['healthInsurance', true],
              placement: 'nested_radio',
            },
            healthInsuranceForFamily: {
              isNested: true,
              label: 'Company paid for employee’s family',
              type: 'radio',
              name: 'healthInsuranceForFamily',
              options: ['Full', 'Partial', 'None'],
              dependence: ['healthInsurance', true],
              placement: 'nested_radio',
            },
            healthInsuranceEligableAfter: {
              isNested: true,
              labelStart: 'Eligible after ',
              labelEnd: ' days',
              type: 'number-select',
              name: 'healthInsuranceEligableAfter',
              placeholder: '-',
              min: 0,
              max: 180,
              dependence: ['healthInsurance', true],
              placement: 'nested_input',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'healthInsuranceForEmployee', value: '' },
          { if: true, field: 'healthInsuranceForFamily', value: '' },
          { if: true, field: 'healthInsuranceEligableAfter', value: '' },
          { if: false, field: 'healthInsuranceForEmployee', value: null },
          { if: false, field: 'healthInsuranceForFamily', value: null },
          { if: false, field: 'healthInsuranceEligableAfter', value: null },
        ],
        placement: 'columns',
      },
    },
  },
  {
    initialValues: {
      dentalInsurance: '',
      dentalInsuranceForEmployee: null,
      dentalInsuranceForFamily: null,
      dentalInsuranceEligableAfter: null,

      visionInsurance: '',
      visionInsuranceForEmployee: null,
      visionInsuranceForFamily: null,
      visionInsuranceEligableAfter: null,
    },
    inputs: {
      dentalInsurance: {
        isNested: true,
        label: 'Company paid dental insurance?',
        type: 'booleanRadio',
        name: 'dentalInsurance',
        options: [
          { value: true, title: 'Yes' },
          {
            dentalInsuranceForEmployee: {
              isNested: true,
              label: 'Company paid for employee',
              type: 'radio',
              name: 'dentalInsuranceForEmployee',
              options: ['Full', 'Partial', 'None'],
              dependence: ['dentalInsurance', true],
              placement: 'nested_radio',
            },
            dentalInsuranceForFamily: {
              isNested: true,
              label: 'Company paid for employee’s family',
              type: 'radio',
              name: 'dentalInsuranceForFamily',
              options: ['Full', 'Partial', 'None'],
              dependence: ['dentalInsurance', true],
              placement: 'nested_radio',
            },
            dentalInsuranceEligableAfter: {
              isNested: true,
              labelStart: 'Eligible after ',
              labelEnd: ' days',
              type: 'number-select',
              name: 'dentalInsuranceEligableAfter',
              placeholder: '-',
              min: 0,
              max: 180,
              dependence: ['dentalInsurance', true],
              placement: 'nested_input',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'dentalInsuranceForEmployee', value: '' },
          { if: true, field: 'dentalInsuranceForFamily', value: '' },
          { if: true, field: 'dentalInsuranceEligableAfter', value: '' },
          { if: false, field: 'dentalInsuranceForEmployee', value: null },
          { if: false, field: 'dentalInsuranceForFamily', value: null },
          { if: false, field: 'dentalInsuranceEligableAfter', value: null },
        ],
        placement: 'columns',
      },
      visionInsurance: {
        isNested: true,
        label: 'Company paid vision insurance?',
        type: 'booleanRadio',
        name: 'visionInsurance',
        options: [
          { value: true, title: 'Yes' },
          {
            visionInsuranceForEmployee: {
              isNested: true,
              label: 'Company paid for employee',
              type: 'radio',
              name: 'visionInsuranceForEmployee',
              options: ['Full', 'Partial', 'None'],
              dependence: ['visionInsurance', true],
              placement: 'nested_radio',
            },
            visionInsuranceForFamily: {
              isNested: true,
              label: 'Company paid for employee’s family',
              type: 'radio',
              name: 'visionInsuranceForFamily',
              options: ['Full', 'Partial', 'None'],
              dependence: ['visionInsurance', true],
              placement: 'nested_radio',
            },
            visionInsuranceEligableAfter: {
              isNested: true,
              labelStart: 'Eligible after ',
              labelEnd: ' days',
              type: 'number-select',
              name: 'visionInsuranceEligableAfter',
              placeholder: '-',
              min: 0,
              max: 180,
              dependence: ['visionInsurance', true],
              placement: 'nested_input',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'visionInsuranceForEmployee', value: '' },
          { if: true, field: 'visionInsuranceForFamily', value: '' },
          { if: true, field: 'visionInsuranceEligableAfter', value: '' },
          { if: false, field: 'visionInsuranceForEmployee', value: null },
          { if: false, field: 'visionInsuranceForFamily', value: null },
          { if: false, field: 'visionInsuranceEligableAfter', value: null },
        ],
        placement: 'columns',
      },
    },
  },
  {
    initialValues: {
      retirementPlan: '',

      paidHolidays: '',
      numberOfPaidHolidays: '',

      paidVacation: '',
      vacation: [{
        bottomExperience: '',
        topExperience: '',
        vacationDays: '',
      }],
    },
    inputs: {
      retirementPlan: {
        isNested: true,
        label: '401K or other retirement savings plan?',
        type: 'booleanRadio',
        name: 'retirementPlan',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        placement: 'columns',
      },

      paidHolidays: {
        isNested: true,
        label: 'Paid holidays?',
        type: 'booleanRadio',
        name: 'paidHolidays',
        options: [
          { value: true, title: 'Yes' },
          {
            numberOfPaidHolidays: {
              isNested: true,
              className: 'number-of-paid-holidays',
              name: 'numberOfPaidHolidays',
              min: 1,
              max: 20,
              dependence: ['paidHolidays', true],
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'numberOfPaidHolidays', value: '' },
          { if: false, field: 'numberOfPaidHolidays', value: null },
        ],
        placement: 'columns',
      },
      paidVacation: {
        isNested: true,
        label: 'Paid vacations?',
        type: 'booleanRadio',
        name: 'paidVacation',
        options: [
          { value: true, title: 'Yes' },
          {
            vacation: {
              isNested: true,
              name: 'vacation',
              dependence: ['paidVacation', true],
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          {
            if: true,
            field: 'vacation',
            value: [{
              bottomExperience: '',
              topExperience: '',
              vacationDays: '',
            }],
          },
          { if: false, field: 'vacation', value: null },
        ],
        placement: 'columns',
      },
    },
  },
  {
    initialValues: {
      bonuses: '',
      bonusesTypes: [],

      passengersAllowed: '',
      passengersTypes: [],

      petsAllowed: '',
      petsTypes: [],
    },
    inputs: {
      bonuses: {
        isNested: true,
        label: 'Bonuses?',
        type: 'booleanRadio',
        name: 'bonuses',
        options: [
          { value: true, title: 'Yes' },
          {
            bonusesTypes: {
              isNested: true,
              type: 'checkbox',
              name: 'bonusesTypes',
              options: [
                'Safe working bonuses',
                'Driving based bonuses',
                'Fuel based bonuses',
                'Other',
              ],
              dependence: ['bonuses', true],
              placement: 'nested_checkbox',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'bonusesTypes', value: [] },
          { if: false, field: 'bonusesTypes', value: null },
        ],
        placement: 'columns',
      },

      passengersAllowed: {
        isNested: true,
        label: 'Do you allow passengers?',
        type: 'booleanRadio',
        name: 'passengersAllowed',
        options: [
          { value: true, title: 'Yes' },
          {
            passengersTypes: {
              isNested: true,
              type: 'checkbox',
              name: 'passengersTypes',
              label: 'For which positions are they allowed?',
              options: allowedValues.passengersTypes,
              dependence: ['passengersAllowed', true],
              placement: 'nested_checkbox',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'passengersTypes', value: [] },
          { if: false, field: 'passengersTypes', value: null },
        ],
        placement: 'columns',
      },

      petsAllowed: {
        isNested: true,
        label: 'Do you allow pets?',
        type: 'booleanRadio',
        name: 'petsAllowed',
        options: [
          { value: true, title: 'Yes' },
          {
            petsTypes: {
              isNested: true,
              type: 'checkbox',
              name: 'petsTypes',
              label: 'For which positions are they allowed?',
              options: allowedValues.petsTypes,
              dependence: ['petsAllowed', true],
              placement: 'nested_checkbox',
            },
          },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'petsTypes', value: [] },
          { if: false, field: 'petsTypes', value: null },
        ],
        placement: 'columns',
      },
    },
  },
]

/* eslint no-param-reassign: "error" */
/* eslint-disable no-return-assign */
companySetUpConfig.map((config, i) => config.schema = companySchema.createCompany.front[i])
