import { sendRequest } from './api'

export const getSchoolJobs = (schoolId, token, jobsBoardType, pageSize, pageNum, filters, similar) => (
  sendRequest(
    'GET',
    `/schools/${schoolId}/jobs`,
    {
      headers: { authorization: `Bearer ${token}` },
      query: {
        ...filters,
        ...similar,
        jobsBoardType,
        pageSize,
        pageNum,
      },
    },
  )
)

export const getJobsBoardExperiencedCompanies = (id, token) => sendRequest(
  'GET',
  `/schools/${id}/jobs-board/experienced/companies`,
  { headers: token ? { authorization: `Bearer ${token}` } : null },
)

export const getJobsBoardEntryCompanies = (id, token) => sendRequest(
  'GET',
  `/schools/${id}/jobs-board/entry/companies`,
  { headers: token ? { authorization: `Bearer ${token}` } : null },
)

export const getJobsBoardExperiencedJobs = (id, token, pageSize, pageNum, filters, similar) => sendRequest(
  'GET',
  `/schools/${id}/jobs-board/experienced/jobs`,
  {
    headers: token ? { authorization: `Bearer ${token}` } : null,
    query: {
      ...filters,
      ...similar,
      pageSize,
      pageNum,
    },
  },
)

export const getJobsBoardEntryJobs = (id, token, pageSize, pageNum, filters, similar) => sendRequest(
  'GET',
  `/schools/${id}/jobs-board/entry/jobs`,
  {
    headers: token ? { authorization: `Bearer ${token}` } : null,
    query: {
      ...filters,
      ...similar,
      pageSize,
      pageNum,
    },
  },
)

export const getJobsBoardExperiencedJob = (schoolId, jobId, token) => sendRequest(
  'GET',
  `/schools/${schoolId}/jobs-board/experienced/jobs/${jobId}`,
  { headers: token ? { authorization: `Bearer ${token}` } : null },
)

export const getJobsBoardEntryJob = (schoolId, jobId, token) => sendRequest(
  'GET',
  `/schools/${schoolId}/jobs-board/entry/jobs/${jobId}`,
  { headers: token ? { authorization: `Bearer ${token}` } : null },
)
