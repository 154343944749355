import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { ROLES } from '../config'

const { persistAtom } = recoilPersist()

export const tokenState = atom({
  key: 'token',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const studentState = atom({
  key: ROLES.STUDENT,
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const adminState = atom({
  key: ROLES.ADMIN,
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const recruiterState = atom({
  key: ROLES.RECRUITER,
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const potentialRecruiter = atom({
  key: 'potentialPendingRecruiter',
  default: null,
})

export const representativeState = atom({
  key: ROLES.REPRESENTATIVE,
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const currentSchoolState = atom({
  key: 'currentSchool',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const roleOfUserState = atom({
  key: 'roleOfUser',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const boardTokensState = atom({
  key: 'boardTokens',
  default: { tokenEntry: null, tokenExperienced: null },
  effects_UNSTABLE: [persistAtom],
})

export const schoolsToPostJobOnState = atom({
  key: 'schoolsToPostJobOn',
  default: [],
})

export const jobBoardsToPostJobOnState = atom({
  key: 'jobBoardsToPostJobOn',
  default: [],
})

export const availableSchoolsToPostJobOnState = atom({
  key: 'availableSchoolsToPostJobOn',
  default: [],
})

export const boardTypeState = atom({
  key: 'boardType',
  default: { value: '', isSwitchingTypesDisabled: false },
})

export const schoolsToClaimState = atom({
  key: 'schoolsToClaim',
  default: [],
})

export const jobState = atom({
  key: 'job',
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const autoRenewState = atom({
  key: 'autoRenew',
  default: true,
  effects_UNSTABLE: [persistAtom],
})
