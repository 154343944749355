import {sendRequest} from "./api";

export const signInStudent = (body) => sendRequest('POST', '/student/sign-in', { body })
export const signUpStudent = (body) => sendRequest('POST', '/student/sign-up', { body })
export const changeStudentSchool = (studentId, schoolId, token) => sendRequest('POST', `/student/${studentId}/school/${schoolId}`, {
    headers: { authorization: `Bearer ${token}` }
})

export const getStudent = (id, token) => (
    sendRequest('GET', `/students/${id}`, { headers: { authorization: `Bearer ${token}` } })
)