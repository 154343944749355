import { constants, authConstants, jobSchema } from '@job-board/job-board-validation'

export const ROUTE_PATH_PARAMS = {
  JOBS: {
    ID: 'jobId',
  },
  SCHOOLS: {
    ID: 'schoolId',
    BOARD_TYPE: 'boardType',
  },
  APPLICATION: {
    ID: 'id',
  },
  REPRESENTATIVE: {
    ID: 'id',
  },
  COMPANIES: {
    ID: 'id',
  },
  ADMINS: {
    SCHOOL: {
      ID: 'id'
    }
  }
}

export const ROUTE_NAMES = {
  HOME: '/',
  NOT_FOUND: '/not-found',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  PRIVACY_NOTICE: '/privacy-notice',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  APPLICATION: {
    DOMAIN: `/school/:${ROUTE_PATH_PARAMS.SCHOOLS.ID}/board/:${ROUTE_PATH_PARAMS.SCHOOLS.BOARD_TYPE}/:${ROUTE_PATH_PARAMS.JOBS.ID}/application`,
    SUB_DOMAIN: `/board/:${ROUTE_PATH_PARAMS.SCHOOLS.BOARD_TYPE}/:${ROUTE_PATH_PARAMS.JOBS.ID}/application`,
  },
  JOBS: {
    NEW: '/job/new',
    EDIT: `/job/:${ROUTE_PATH_PARAMS.JOBS.ID}/edit`,
    BY_ID: `/job/:${ROUTE_PATH_PARAMS.JOBS.ID}`,
  },
  COMPANY: {
    SET_UP: '/company/set-up',
    EDIT: '/company/edit',
    SETTINGS: '/company/settings',
  },
  RECRUITER: {
    SIGN_UP: '/recruiter/sign-up',
    SIGN_UP_INVITED: '/recruiter/invite/sign-up',
    EDIT: '/recruiter/edit',
    PAYMENT_METHOD: '/recruiter/payment-method',
    JOBS: '/recruiter/jobs',
    SETTINGS: '/recruiter/settings',
    APPLICATIONS: '/recruiter/applications'
  },
  STUDENT: {
    SIGN_UP: '/student/sign-up',
    SIGN_UP_INVITED: '/student/invite/sign-up',
    EDIT: '/student/edit',
    JOBS: '/student/jobs',
    SETTINGS: '/student/settings',
    APPLICATION_FORM_EDIT: '/student/application/edit',
    APPLICATIONS: '/student/applications'
  },
  SCHOOL: {
    SIGN_UP: '/school/sign-up',
    CLAIM: '/school/claim',
    EDIT: `/school/edit/:${ROUTE_PATH_PARAMS.SCHOOLS.ID}`,
    BOARD: `/school/:${ROUTE_PATH_PARAMS.SCHOOLS.ID}/board`,
    SUB_DOMAIN_BOARD: '/',

    BOARD_JOB: `/school/:${ROUTE_PATH_PARAMS.SCHOOLS.ID}/board/:${ROUTE_PATH_PARAMS.SCHOOLS.BOARD_TYPE}/:${ROUTE_PATH_PARAMS.JOBS.ID}`,
    SUB_DOMAIN_BOARD_JOB: `/board/:${ROUTE_PATH_PARAMS.SCHOOLS.BOARD_TYPE}/:${ROUTE_PATH_PARAMS.JOBS.ID}`,

    SETTINGS: '/school/settings',
    SEARCH: '/school/search',

    SCHOOLS_LIST: '/schools/list'
  },
  REPRESENTATIVE: {
    EDIT: `/representative/:${ROUTE_PATH_PARAMS.REPRESENTATIVE.ID}`,
  },
  ADMIN: {
    LOGIN: '/admin/log-in',
    IMPORT: '/admin/schools/import',
    REPORTS: '/admin/reports',
    SCHOOLS_LIST: '/admin/schools/list',
    SETTINGS: '/admin/settings',
    COMPANIES_LIST: '/admin/companies/list',
    COMPANY: `/admin/company/:${ROUTE_PATH_PARAMS.COMPANIES.ID}`,
    RECRUITERS: `/admin/recruiters/list`,
    SCHOOL: {
      SETTINGS: `/admin/school/:${ROUTE_PATH_PARAMS.ADMINS.SCHOOL.ID}/settings`
    }
  },
}

export const ENV = process.env.REACT_APP_ENV
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
export const BACKEND_STATIC_URL = process.env.REACT_APP_BACKEND_STATIC
export const DOMAIN = process.env.REACT_APP_DOMAIN
export const STRIPE_PUBLISHABLE_TOKEN = process.env.REACT_APP_STRIPE_PUBLISHABLE_TOKEN

export const BODY_TYPES = {
  JSON: 'application/json',
  MULTIPART: 'multipart/form-data',
  BLOB: 'blob'
}

export const POPUP_MESSAGES = {
  importSchools: {
    title: 'No errors were detected',
    message: 'Your Schools has been imported',
  },
  claimSchool: {
    message: 'Thank you for claiming your school!',
    secondMessage: `To verify you are authorized to set up this board
    we have sent an email to the address we have on
    file for your school. Please check this email and
    click verify to complete your job board set-up. There is a chance this will end up in your spam folder, so please check there if you do not see it.`,
  },
  signUpSchool: {
    title: 'Your school is signed up',
    message: (schoolEmail) => `To complete your job board setup, please check
    ${schoolEmail} and click the “Verify” button in the email we sent you. There is a chance this will end up in your spam folder, so please check there if you do not see it.`,
  },
}

export const { ROLES } = authConstants

export const {
  ERROR_NAMES, ERROR_CODES, STATUS_CODES, JOB_STATUSES, ACTIONS,
  JOBS_BOARD_PRIVACY_TYPES, RECRUITER_STATUSES,
  JOBS_BOARD_TYPES, DEFAULTS, JOB_LOCATION_TYPE, PAY_STRUCTURE, SCHOOLS_TYPE,
  NOTIFICATION_ABOUT_NEW_BOARD, PRIOR_EXPERIENCE_NEEDED, APPLICATIONS_TYPE
} = constants

export const RECEIVE_NOTIFICATION_TYPE = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  EVERY_TO_WEEKS: 'Every Two Weeks'
}

export const JOB_POSITIONS = [...new Set([
  ...jobSchema.allowedValues.positionLocal,
  ...jobSchema.allowedValues.positionOTR,
  ...jobSchema.allowedValues.positionRegional,
  ...jobSchema.allowedValues.positionTeamDriving,
])]

export const ERROR_MESSAGES = {
  INTERNAL: 'We are having some technical issues on our end. We are very sorry.',
  UNAUTHORIZED: 'Sorry, but this action is unauthorized.',
  NOT_VALID_TOKEN: 'Sorry, but something went wrong with authorization.',
  NOT_VALID_CREDENTIALS: 'The email address or password provided is incorrect.',
  FORBIDDEN: 'Sorry, but you don\'t have access to this functionality.',
  RECRUITER_EXISTS: 'This recruiter already exists in our system',
  STUDENT_EXISTS: 'This driver already exists in our system',
  REPRESENTATIVE_EXISTS: 'This representative already exists in our system',
  ACTION_EXPIRED: 'The time for this action was expired',
  REFERRAL_CODE_EXISTS: 'The referal code already exists in our system.',
}

export const SCHOOL_SETTINGS_SECTIONS = [
  'Logo', 'Edit school', 'Blocked Companies', 'Visibility', 'Web Address', 'Color Schema', 'Flyer', 'Snippet',
]

export const ADMIN_SCHOOL_SETTINGS_SECTIONS = [
  'Logo', 'Edit school', 'Blocked Companies', 'Visibility', 'Web Address', 'Color Schema', 'Flyer', 'Snippet',
]
export const COMPANY_SETTINGS_SECTIONS = [
  'Logo', 'Edit company', 'Pending recruiters', 'Approved recruiters', 'Invite recruiter', 'Application form', 'Notification',
  'Discount codes and referrals ',
]
export const DRIVER_SETTINGS_SECTIONS = [
  'My Application Info', 'Affiliated School'
]
export const RECRUITER_SETTINGS_SECTIONS = [
  'Discount codes and referrals ',
]

export const ADMIN_SETTINGS_SECTIONS = [
  'Job Price',
  'Discount codes',
]

export const DEFAULT_COLORS_FOR_SCHOOL_BOARD = {
  primaryTextColor: '#000000',
  bannerColor: '#54BE73',
  whiteColor: '#ffffff',
  disabledPrimaryTextColor: '#858585',
  disabledBannerColor: '#B2B2B2',
}

export const JOB_OPTIONS = {
  [JOB_LOCATION_TYPE.LOCAL]: 'localJobOptions',
  [JOB_LOCATION_TYPE.REGIONAL]: 'regionalJobOptions',
  [JOB_LOCATION_TYPE.OVER_THE_ROAD]: 'OTRJobOptions',
  [JOB_LOCATION_TYPE.TEAM_DRIVING]: 'teamDrivingJobOptions',
}

export const JOB_LIST_FILTERS_DATES = [
  { title: 'Published', fields: ['lastPaymentAfter', 'lastPaymentBefore'], placeholder: 'Choose publication date of post' },
  { title: 'Expiration date', fields: ['expiresAfter', 'expiresBefore'], placeholder: 'Choose expiration date of post' },
]

export const JOB_LIST_FILTER_STATUSES = [
  JOB_STATUSES.APPROVED,
  JOB_STATUSES.EXPIRED,
  JOB_STATUSES.UNPAID,
]

export const ZIP_DISTANCE = ['5', '10', '25', '50', '100', '250']

export const MEDIA_QUERY = {
  MOBILE: { maxWidth: 415 },
  TABLET: { minWidth: 415, maxWidth: 821 },
  LAPTOP: { minWidth: 821, maxWidth: 1200 },
  DESKTOP: { minWidth: 1200 },

  MOBILE_TABLET: { maxWidth: 821 },
  MOBILE_TABLET_LAPTOP: { maxWidth: 1200 },
}

export const FORM_CONFIG_IGONRED_KEYS = [
  'isNested', 'disabled', 'horizontal', 'paragraphs',
  'array', 'placeholder', 'className', 'type', 'customKey',
  'step', 'label', 'placement', 'placementClass', 'inArray',
  'minLabel', 'maxLabel', 'dependence', 'indexImpact', 'onChange',
]

export const ERROR_CLASSES_INGORED_NAMES = [
  'localJobOptions.hourlyPayOptions.paymentForExperienced',
  'reportingLocation',
  'regionalJobOptions',
  'localJobOptions',
  'OTRJobOptions',
  'teamDrivingJobOptions',
  'vacation',
  'address',
  'addressHQ',
]

export const REDIRECT_IDS = {
  CREATE_JOB: 'homepage-find-new-job',
}
