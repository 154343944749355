import { sendRequest } from './api'

export const loginAdmin = (data) => sendRequest('POST', '/admins/login', { body: data })

export const getAdmin = (id, token) => (
  sendRequest('GET', `/admins/${id}`, { headers: { authorization: `Bearer ${token}` } })
)

export const getJobPrice = () => (
  sendRequest('GET', '/admin/common/job/price')
)

export const patchJobPrice = (data, token) => (
  sendRequest('PATCH', '/admin/common/job/price', {
    body: data,
    headers: { authorization: `Bearer ${token}` },
  })
)

export const getRecruitersOfCompanyById = (id, query, token) => sendRequest('GET', `/company/${id}/recruiters`, {
  headers: { authorization: `Bearer ${token}` },
  query,
})
