import { BODY_TYPES } from '../config'
import { sendRequest } from './api'

export const createSchool = (data) => sendRequest('POST', '/schools', { body: data })

export const createRepresentativeSchool = (data, id, token) => sendRequest(
  'POST',
  `/representatives/${id}/schools`,
  { body: data, headers: { authorization: `Bearer ${token}` } },
)

export const importSchools = (name, file, token) => {
  const data = new FormData()
  data.append(name, file, file.name)

  return sendRequest('POST', '/schools/import', {
    body: data,
    headers: { authorization: `Bearer ${token}` },
    type: BODY_TYPES.MULTIPART,
  })
}

export const claimSchool = (data, token) => sendRequest('POST', '/schools/claim', {
  body: data,
  headers: { authorization: `Bearer ${token}` },
})

export const claimSchoolAndCreateRepresentative = (data) => sendRequest(
  'POST',
  '/schools/claim',
  { body: data },
)

export const getListOfSchools = (query, token) => (
  sendRequest('GET', '/schools', { query, headers: token ? { authorization: `Bearer ${token}` } : null })
)

export const increaseUnclaimedSchoolsShownTimes = (schoolsIds, token) => (
  sendRequest(
    'POST',
    '/schools/unclaimed/shown/increase',
    { body: schoolsIds, headers: { authorization: `Bearer ${token}` } },
  )
)

export const increaseUnclaimedSchoolBoardShownTimes = (schoolId) => (
  sendRequest(
    'POST',
    `/schools/unclaimed/${schoolId}/viewed/increase`,
  )
)

export const editSchool = (id, token, data) => (
  sendRequest(
    'PATCH',
    `/schools/${id}`,
    { body: data, headers: { authorization: `Bearer ${token}` } },
  )
)

export const getSchool = (id, token) => (
  sendRequest('GET', `/schools/${id}`, { headers: token ? { authorization: `Bearer ${token}` } : null })
)

export const getSchoolBySubdomain = (subdomain) => (
  sendRequest('GET', `/schools/subdomain/${subdomain}`)
)

export const uploadSchoolImage = (id, token, data) => (
  sendRequest(
    'POST',
    `/schools/${id}/avatar`,
    { body: data, headers: { authorization: `Bearer ${token}` } },
  )
)

export const blockCompanyBySchool = (schoolId, companyId, token) => (
  sendRequest('POST', `/schools/${schoolId}/companies/${companyId}/block`, { headers: { authorization: `Bearer ${token}` } })
)

export const unblockCompanyBySchool = (schoolId, companyId, token) => (
  sendRequest('POST', `/schools/${schoolId}/companies/${companyId}/unblock`, { headers: { authorization: `Bearer ${token}` } })
)

export const approveSchoolClaim = (schoolId, token) => (
  sendRequest(
    'POST',
    `/schools/${schoolId}/claim/approve`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const rejectSchoolClaim = (schoolId, token) => (
  sendRequest(
    'POST',
    `/schools/${schoolId}/claim/reject`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const approveSchoolCreate = (schoolId, token) => (
  sendRequest(
    'POST',
    `/schools/${schoolId}/approve`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const rejectSchoolCreate = (schoolId, token) => (
  sendRequest(
    'POST',
    `/schools/${schoolId}/reject`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const pinJobBySchool = (schoolId, jobId, token) => (
  sendRequest(
    'POST',
    `schools/${schoolId}/jobs/${jobId}/pin`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const unpinJobBySchool = (schoolId, jobId, token) => (
  sendRequest(
    'POST',
    `schools/${schoolId}/jobs/${jobId}/unpin`,
    { headers: { authorization: `Bearer ${token}` } },
  )
)

export const boardSubscribe = (schoolId, data) => (
  sendRequest(
    'POST',
    `schools/${schoolId}/subscribe`,
    { body: data },
  )
)

export const sendActiveBoardRequest = (schoolId) => (
    sendRequest(
        'POST',
        `schools/${schoolId}/board/active`,
    )
)