import { representativeSchema } from '@job-board/job-board-validation'

import { signUpRecruiterFormConfig } from '../recruiter/signUp'

const inputs = {
  name: signUpRecruiterFormConfig.inputs.name,
  email: signUpRecruiterFormConfig.inputs.email,
  password: signUpRecruiterFormConfig.inputs.password,
}

const initialValues = {
  name: '',
  email: '',
  password: '',
}

const schema = representativeSchema.patchRepresentative.front
/* eslint-disable import/prefer-default-export */
export const patchRepresentativeConfig = {
  schema,
  initialValues,
  inputs,
}
