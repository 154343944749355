import { nanoid } from 'nanoid'
import cloneDeep from 'lodash/cloneDeep'
import { jobSchema } from '@job-board/job-board-validation'
import { JOB_LOCATION_TYPE, PAY_STRUCTURE, PRIOR_EXPERIENCE_NEEDED } from '../config'

const { allowedValues } = jobSchema
const { GRADUATE, MORE_THAN_SCHOOL } = PRIOR_EXPERIENCE_NEEDED
const {
  LOCAL, REGIONAL, OVER_THE_ROAD, TEAM_DRIVING,
} = JOB_LOCATION_TYPE
const { HOURLY, MILEAGE, PERCENTAGE_OF_LOAD } = PAY_STRUCTURE

export const createJobGroupInitialValues = [
  {
    jobLocationType: '',
    position: '',
    otherPositionName: ''
  }, {
    jobLocationType: '',
    reportingLocation: {
      city: '',
      state: '',
      zip: '',
      requiredLicenseType: '',
    },
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    schools: [],
    priorExperienceNeeded: '',
    yearsOfExperienceNeeded: '',
    boardType: '',
  }, {
    jobLocationType: '',
    reportingLocation: {
      endorsements: {
        doublesOrTriples: '',
        tanker: '',
        hazardousMaterials: '',
        manualTransmissionRestriction: '',
      },
    },
    TWICCardRequired: '',
    minimumAge: 18,
  }, {
    jobLocationType: '',
    tuitionReimbursement: '',
    signOnBonus: '',
    signOnBonusMaxAmount: '',
    signOnBonusPayablePeriod: 1,
  }, {
    jobLocationType: '',

    localJobOptions: {
      fullTime: '',
      yearRoundWork: '',
    },

    regionalJobOptions: {
      fullTime: '',
      yearRoundWork: '',
      payStructure: '',

      hourlyPayOptions: {
        entry: {
          startingPay: []
        },
        experienced: {
          startingPay: []
        },

        numberOfHoursPerWeek: 1,
        mandatoryOvertime: '',
        overtimeAfter8HoursDay: '',
        overtimeAfter40HoursWeek: '',
      },

      mileagePayOptions: {
        entry: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100,
        },
        experienced: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100,
        }
      },

      percentageOfLoadPayOptions: {
        entry: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        },

        experienced: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        }
      },
    },
    OTRJobOptions: {
      fullTime: '',
      yearRoundWork: '',

      payStructure: '',

      hourlyPayOptions: {
        entry: {
          startingPay: []
        },

        experienced: {
          startingPay: []
        },
        numberOfHoursPerWeek: 1,
        mandatoryOvertime: '',
        overtimeAfter8HoursDay: '',
        overtimeAfter40HoursWeek: '',
      },
      mileagePayOptions: {
        entry: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100
        },

        experienced: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100
        }
      },
      percentageOfLoadPayOptions: {
        entry: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        },

        experienced: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        }
      },
    },
    teamDrivingJobOptions: {
      fullTime: '',
      yearRoundWork: '',

      payStructure: '',

      hourlyPayOptions: {
        entry: {
          startingPay: []
        },

        experienced: {
          startingPay: []
        },
        numberOfHoursPerWeek: 1,
        mandatoryOvertime: '',
        overtimeAfter8HoursDay: '',
        overtimeAfter40HoursWeek: '',
      },
      mileagePayOptions: {
        entry: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100,
        },

        experienced: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100,
        }
      },
      percentageOfLoadPayOptions: {
        entry: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        },

        experienced: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        }
      },
    },
  }, {
    jobLocationType: '',

    localJobOptions: {
      payStructure: '',

      hourlyPayOptions: {
        entry: {
          beginnersPay: [],
        },

        experienced: {
          beginnersPay: [],
        }
      },
      mileagePayOptions: {
        entry: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100,
        },

        experienced: {
          startingRatePerMile: [],
          avarageMilesPerWeek: 100,
        }
      },
      percentageOfLoadPayOptions: {
        entry: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        },

        experienced: {
          driversPayPercentage: '',
          driversExpectedPay: '',
        }
      },
    },

    regionalJobOptions: {
      multiStopPay: '',
      layoverPay: '',
      breakdownPay: '',
      payPerDiem: '',
      dedicatedRoute: '',
      physicalRequirements: '',
      percentDropAndHook: 100,
    },

    OTRJobOptions: {
      multiStopPay: '',
      layoverPay: '',
      detentionPay: '',
      breakdownPay: '',
      payPerDiem: '',
      dedicatedRoute: '',
      physicalRequirements: '',
      percentDropAndHook: 100,
      numberOfNightsAway: '',
      avarageTimeAtHome: '',
    },

    teamDrivingJobOptions: {
      multiStopPay: '',
      layoverPay: '',
      detentionPay: '',
      breakdownPay: '',
      payPerDiem: '',
      dedicatedRoute: '',
      physicalRequirements: '',
      percentDropAndHook: 100,
      numberOfNightsAway: '',
      avarageTimeAtHome: '',
    },

  }, {
    jobLocationType: '',
    localJobOptions: {
      numberOfHoursPerWeek: 1,
      mandatoryOvertime: '',
      overtimeAfter8HoursDay: '',
      overtimeAfter40HoursWeek: '',
    },
    regionalJobOptions: {
      numberOfNightsAway: '',
      numberOfConsecutiveNightsAwayPerWeek: '',
      restPeriodWithSleeperBerths: '',
      companyProvidesMotels: '',
      drivingStates: [],
      drivingPercentage: 100,
    },
    OTRJobOptions: {
      restPeriodWithSleeperBerths: '',
      companyProvidesMotels: '',
      drivingStates: [],
      drivingPercentage: 100,
    },
    teamDrivingJobOptions: {
      restPeriodWithSleeperBerths: '',
      companyProvidesMotels: '',
      drivingStates: [],
      drivingPercentage: 100,
    },
  }, {
    jobLocationType: '',
    localJobOptions: {
      workDaysVary: '',
      workDays: [],
      workHoursVary: '',
      workHoursType: '',
    },
    jobsBoards: [],
  }, {
    jobLocationType: '',
    localJobOptions: {
      physicalRequirements: '',
      requiredLoadUnload: '',
      drivingPercentage: 100,
    },
    paymentMethod: false,
    autoRenew: 'true',
  }, {
    jobLocationType: '',
    jobsBoards: [],
  },
  {
    jobLocationType: '',
    autoRenew: 'true',
    paymentMethod: false,
  },
]

export const createJobGroupInputs = [
  {
    jobLocationType: {
      isNested: true,
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),
      position: {
        isNested: true,
        type: 'radio',
        name: 'position',
        placement: 'columns',
        label: 'Position Name',
        options: allowedValues.positionLocal,
      },
    },
    regionalJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', REGIONAL],
      customKey: nanoid(),
      position: {
        isNested: true,
        type: 'radio',
        name: 'position',
        placement: 'columns',
        label: 'Position Name',
        options: allowedValues.positionRegional,
      },
    },
    OTRJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', OVER_THE_ROAD],
      customKey: nanoid(),
      position: {
        isNested: true,
        type: 'radio',
        name: 'position',
        placement: 'columns',
        label: 'Position Name',
        options: allowedValues.positionOTR,
      },
    },
    teamDrivingJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', TEAM_DRIVING],
      customKey: nanoid(),
      position: {
        isNested: true,
        type: 'radio',
        name: 'position',
        placement: 'columns',
        label: 'Position Name',
        options: allowedValues.positionTeamDriving,
      },
    },
    otherPositionNameValue: {
      isNested: true,
      placement: 'columns',
      label: 'Other position name',
      dependence: ['position', 'Other'],

      otherPositionName: {
        name: 'otherPositionName',
        type: 'text',
        className: 'input input--large',
        placeholder: 'Position name',
      }
    },
  },
  {
    jobLocationType: {
      isNested: true,
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      disabled: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
    },
    reportingLocation: {
      isNested: true,
      placement: 'columns',
      label: 'Driver\'s Reporting Location',
      city: {
        name: 'reportingLocation.city',
        type: 'text',
        className: 'input input--large',
        placeholder: 'City name',
      },
      state: {
        isNested: true,
        type: 'search-select',
        className: 'input input--large input--no-arrows',
        name: 'reportingLocation.state',
        options: allowedValues.states.map((s) => ({
          label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
        })),
        isMulti: false,
        placeholder: 'State',
      },
      zip: {
        name: 'reportingLocation.zip',
        type: 'text',
        className: 'input input--large input--no-arrows',
        placement: 'columns',
        placeholder: 'Zip code',
      },
    },
    contactPerson: {
      isNested: true,
      placement: 'columns',
      label: 'Job contact',
      name: {
        name: 'contactPersonName',
        type: 'text',
        className: 'input input--large',
        placeholder: 'Person',
      },
      phoneNumber: {
        name: 'contactPersonPhoneNumber',
        isNested: true,
        type: 'phone-number',
        className: 'input',
        placeholder: 'Phone number',
      },
    },
    schools: {
      isNested: true,
      label: 'Would you like to add this job post to other schools’ job boards?',
      type: 'job-board-search',
      name: 'schools',
      placement: 'columns',
      multiple: true,
      options: [],
    },
    priorExperienceNeeded: {
      isNested: true,
      name: 'priorExperienceNeeded',
      label: 'Prior experience needed?',
      placement: 'columns',
      type: 'radio',
      options: allowedValues.priorExperienceNeeded,
      impact: [
        { if: GRADUATE, field: 'yearsOfExperienceNeeded', value: null },
        { if: MORE_THAN_SCHOOL, field: 'yearsOfExperienceNeeded', value: createJobGroupInitialValues[1].yearsOfExperienceNeeded },
      ],
    },
    yearsOfExperienceNeeded: {
      isNested: true,
      name: 'yearsOfExperienceNeeded',
      placement: 'columns',
      label: ' ',
      type: 'number-select',
      min: 0,
      max: 30,
      labelStart: 'Years of experience needed: ',
      dependence: ['priorExperienceNeeded', MORE_THAN_SCHOOL],
    },
    boardType: {
      isNested: true,
      name: 'boardType',
      label: 'Which type of boards?',
      placement: 'columns',
      type: 'radio',
      options: allowedValues.boardType,
    },
    requiredLicenseType: {
      isNested: true,
      label: 'License Type Required',
      placement: 'columns',
      type: 'radio',
      name: 'reportingLocation.requiredLicenseType',
      options: allowedValues.requiredLicenseType,
    },
  },
  {
    jobLocationType: {
      isNested: true,
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      disabled: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
    },
    endorsements: {
      isNested: true,
      placement: 'columns',
      label: 'Endorsements required',
      doublesOrTriples: {
        isNested: true,
        type: 'radio',
        placement: 'nested_radio',
        label: 'Doubles/Triples',
        name: 'reportingLocation.endorsements.doublesOrTriples',
        options: allowedValues.endorsementsDoublesOrTriples,
      },
      tanker: {
        isNested: true,
        label: 'Tanker',
        type: 'radio',
        placement: 'nested_radio',
        name: 'reportingLocation.endorsements.tanker',
        options: allowedValues.endorsementsTanker,
      },
      hazardousMaterials: {
        isNested: true,
        label: 'Hazardous Materials',
        type: 'radio',
        placement: 'nested_radio',
        name: 'reportingLocation.endorsements.hazardousMaterials',
        options: allowedValues.endorsementsHazardousMaterials,
      },
      manualTransmissionRestriction: {
        isNested: true,
        label: 'Accept Automatic Transmission Only?',
        type: 'booleanRadio',
        placement: 'nested_radio',
        name: 'reportingLocation.endorsements.manualTransmissionRestriction',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
    },
    TWICCardRequired: {
      isNested: true,
      placement: 'columns',
      label: 'TWIC Card Required?',
      type: 'booleanRadio',
      name: 'TWICCardRequired',
      options: [
        { value: true, title: 'Yes' },
        { value: false, title: 'No' },
      ],
    },
    minimumAge: {
      isNested: true,
      placement: 'columns',
      label: 'What is the minimum age requirement?',
      name: 'minimumAge',
      type: 'number-select',
      min: 18,
      max: 25,
    },
  },
  {
    jobLocationType: {
      isNested: true,
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      disabled: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
    },
    tuitionReimbursement: {
      isNested: true,
      label: 'Tuition reimbursement?',
      type: 'booleanRadio',
      name: 'tuitionReimbursement',
      placement: 'columns',
      options: [
        { value: true, title: 'Yes' },
        { value: false, title: 'No' },
      ],
    },
    signOnBonus: {
      isNested: true,
      label: 'Sign on bonus?',
      type: 'booleanRadio',
      placement: 'columns',
      name: 'signOnBonus',
      options: [
        { value: true, title: 'Yes' },
        {
          signOnBonusMaxAmount: {
            isNested: true,
            name: 'signOnBonusMaxAmount',
            type: 'price-input',
            step: 1,
            placement: 'nested_input',
            label: 'Maximum amount:',
            dependence: ['signOnBonus', true],
          },
          signOnBonusPayablePeriod: {
            isNested: true,
            name: 'signOnBonusPayablePeriod',
            label: 'Payable over what time period:',
            type: 'counter-buttons',
            horizontal: true,
            textUnit: ' Months',
            placement: 'center',
            min: 1,
            max: 36,
            dependence: ['signOnBonus', true],
          },
        },
        { value: false, title: 'No' },
      ],
      impact: [
        { if: true, field: 'signOnBonusMaxAmount', value: createJobGroupInitialValues[3].signOnBonusMaxAmount },
        { if: true, field: 'signOnBonusPayablePeriod', value: createJobGroupInitialValues[3].signOnBonusPayablePeriod },
        { if: false, field: 'signOnBonusMaxAmount', value: null },
        { if: false, field: 'signOnBonusPayablePeriod', value: null },
      ],
    },
  },
  {
    jobLocationType: {
      isNested: true,
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      disabled: true,
      options: allowedValues.jobLocationType,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[4].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'localJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[4].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[4].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[4].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      fullTime: {
        isNested: true,
        label: 'Full Time?',
        placement: 'center',
        type: 'booleanRadio',
        name: 'localJobOptions.fullTime',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      yearRoundWork: {
        isNested: true,
        label: 'Year-round work?',
        type: 'booleanRadio',
        placement: 'center',
        name: 'localJobOptions.yearRoundWork',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
    },
    regionalJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', REGIONAL],

      fullTime: {
        isNested: true,
        placement: 'center',
        label: 'Full time?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.fullTime',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      yearRoundWork: {
        isNested: true,
        placement: 'center',
        label: 'Year-round work?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.yearRoundWork',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      payStructure: {
        isNested: true,
        label: 'Pay structure',
        type: 'radio',
        placement: 'center',
        horizontal: true,
        name: 'regionalJobOptions.payStructure',
        options: [
          { value: HOURLY, title: HOURLY },
          { value: MILEAGE, title: MILEAGE },
          { value: PERCENTAGE_OF_LOAD, title: '% of load' },
        ],
        impact: [
          { if: HOURLY, field: 'regionalJobOptions.hourlyPayOptions', value: createJobGroupInitialValues[4].regionalJobOptions.hourlyPayOptions },
          { if: HOURLY, field: 'regionalJobOptions.mileagePayOptions', value: null },
          { if: HOURLY, field: 'regionalJobOptions.percentageOfLoadPayOptions', value: null },

          { if: MILEAGE, field: 'regionalJobOptions.hourlyPayOptions', value: null },
          { if: MILEAGE, field: 'regionalJobOptions.mileagePayOptions', value: createJobGroupInitialValues[4].regionalJobOptions.mileagePayOptions },
          { if: MILEAGE, field: 'regionalJobOptions.percentageOfLoadPayOptions', value: null },

          { if: PERCENTAGE_OF_LOAD, field: 'regionalJobOptions.hourlyPayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'regionalJobOptions.mileagePayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'regionalJobOptions.percentageOfLoadPayOptions', value: createJobGroupInitialValues[4].regionalJobOptions.percentageOfLoadPayOptions },
        ],
      },
      hourlyPayOptions: {
        isNested: true,
        placement: 'center',

        startingPayEntry: {
          isNested: true,
          name: 'regionalJobOptions.hourlyPayOptions.entry.startingPay',
          type: 'price-input-range',
          className: 'input--no-arrows',
          placeholder: '0.00',
          placement: 'nested_input',
          label: 'Entry level: Starting hourly rate',
          dependence: ['regionalJobOptions.payStructure', HOURLY],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['regionalJobOptions.payStructure', HOURLY]
        },

        startingPayExp: {
          isNested: true,
          name: 'regionalJobOptions.hourlyPayOptions.experienced.startingPay',
          type: 'price-input-range',
          className: 'input--no-arrows',
          placeholder: '0.00',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting hourly rate',
          dependence: ['regionalJobOptions.payStructure', HOURLY],
        },

        numberOfHoursPerWeek: {
          isNested: true,
          placement: 'center',
          name: 'regionalJobOptions.hourlyPayOptions.numberOfHoursPerWeek',
          type: 'counter-buttons',
          min: 1,
          max: 100,
          label: 'Number of hours per week ',
          placeholder: '-',
          dependence: ['regionalJobOptions.payStructure', HOURLY],
        },
        mandatoryOvertime: {
          isNested: true,
          placement: 'center',
          label: 'Is overtime mandatory',
          type: 'booleanRadio',
          name: 'regionalJobOptions.hourlyPayOptions.mandatoryOvertime',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['regionalJobOptions.payStructure', HOURLY],
        },
        overtimeAfter8HoursDay: {
          isNested: true,
          placement: 'center',
          label: 'Overtime after 8-hour day',
          type: 'booleanRadio',
          name: 'regionalJobOptions.hourlyPayOptions.overtimeAfter8HoursDay',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['regionalJobOptions.payStructure', HOURLY],
        },
        overtimeAfter40HoursWeek: {
          isNested: true,
          placement: 'center',
          label: 'Overtime after 40-hour day',
          type: 'booleanRadio',
          name: 'regionalJobOptions.hourlyPayOptions.overtimeAfter40HoursWeek',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['regionalJobOptions.payStructure', HOURLY],
        },
      },
      mileagePayOptions: {
        isNested: true,
        placement: 'center',

        startingRatePerMileEntry: {
          isNested: true,
          name: 'regionalJobOptions.mileagePayOptions.entry.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Entry level: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
          dependence: ['regionalJobOptions.payStructure', MILEAGE],
        },

        avarageMilesPerWeekEntry: {
          name: 'regionalJobOptions.mileagePayOptions.entry.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Entry level: Average miles per week? ',
          dependence: ['regionalJobOptions.payStructure', MILEAGE],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['regionalJobOptions.payStructure', MILEAGE],
        },

        startingRatePerMileExp: {
          isNested: true,
          name: 'regionalJobOptions.mileagePayOptions.experienced.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
          dependence: ['regionalJobOptions.payStructure', MILEAGE],
        },

        avarageMilesPerWeekExp: {
          name: 'regionalJobOptions.mileagePayOptions.experienced.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Experienced Driver: Average miles per week? ',
          dependence: ['regionalJobOptions.payStructure', MILEAGE],
        },

      },
      percentageOfLoadPayOptions: {
        isNested: true,
        placement: 'center',

        driversPayPercentageEntry: {
          isNested: true,
          placement: 'center',
          name: 'regionalJobOptions.percentageOfLoadPayOptions.entry.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Entry level: Driver is paid',
          labelEnd: '% of the load',
          dependence: ['regionalJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        driversExpectedPayEntry: {
          name: 'regionalJobOptions.percentageOfLoadPayOptions.entry.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Entry level: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
          dependence: ['regionalJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['regionalJobOptions.payStructure', PERCENTAGE_OF_LOAD]
        },

        driversPayPercentageExp: {
          isNested: true,
          placement: 'center',
          name: 'regionalJobOptions.percentageOfLoadPayOptions.experienced.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Experienced Driver: Driver is paid',
          labelEnd: '% of the load',
          dependence: ['regionalJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        driversExpectedPayExp: {
          name: 'regionalJobOptions.percentageOfLoadPayOptions.experienced.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Experienced Driver: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
          dependence: ['regionalJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        }
      },
    },
    OTRJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', OVER_THE_ROAD],

      fullTime: {
        isNested: true,
        placement: 'center',
        label: 'Full Time?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.fullTime',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      yearRoundWork: {
        isNested: true,
        placement: 'center',
        label: 'Year-round work?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.yearRoundWork',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      payStructure: {
        isNested: true,
        placement: 'center',
        horizontal: true,
        label: 'Pay structure',
        type: 'radio',
        name: 'OTRJobOptions.payStructure',
        options: [
          { value: HOURLY, title: HOURLY },
          { value: MILEAGE, title: MILEAGE },
          { value: PERCENTAGE_OF_LOAD, title: '% of load' },
        ],
        impact: [
          { if: HOURLY, field: 'OTRJobOptions.hourlyPayOptions', value: createJobGroupInitialValues[4].OTRJobOptions.hourlyPayOptions },
          { if: HOURLY, field: 'OTRJobOptions.mileagePayOptions', value: null },
          { if: HOURLY, field: 'OTRJobOptions.percentageOfLoadPayOptions', value: null },

          { if: MILEAGE, field: 'OTRJobOptions.hourlyPayOptions', value: null },
          { if: MILEAGE, field: 'OTRJobOptions.mileagePayOptions', value: createJobGroupInitialValues[4].OTRJobOptions.mileagePayOptions },
          { if: MILEAGE, field: 'OTRJobOptions.percentageOfLoadPayOptions', value: null },

          { if: PERCENTAGE_OF_LOAD, field: 'OTRJobOptions.hourlyPayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'OTRJobOptions.mileagePayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'OTRJobOptions.percentageOfLoadPayOptions', value: createJobGroupInitialValues[4].OTRJobOptions.percentageOfLoadPayOptions },
        ],
      },
      hourlyPayOptions: {
        isNested: true,
        placement: 'center',

        startingPayEntry: {
          isNested: true,
          name: 'OTRJobOptions.hourlyPayOptions.entry.startingPay',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Entry level: Starting hourly rate',
          dependence: ['OTRJobOptions.payStructure', HOURLY],
          className: 'input--no-arrows',
          placeholder: '0.00',
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['OTRJobOptions.payStructure', HOURLY],
        },

        startingPayExp: {
          isNested: true,
          name: 'OTRJobOptions.hourlyPayOptions.experienced.startingPay',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting hourly rate',
          dependence: ['OTRJobOptions.payStructure', HOURLY],
          className: 'input--no-arrows',
          placeholder: '0.00',
        },
        numberOfHoursPerWeek: {
          isNested: true,
          placement: 'center',
          name: 'OTRJobOptions.hourlyPayOptions.numberOfHoursPerWeek',
          type: 'counter-buttons',
          min: 1,
          max: 100,
          label: 'Number of hours per week',
          placeholder: '-',
          dependence: ['OTRJobOptions.payStructure', HOURLY],
        },
        mandatoryOvertime: {
          isNested: true,
          placement: 'center',
          label: 'Is overtime mandatory',
          type: 'booleanRadio',
          name: 'OTRJobOptions.hourlyPayOptions.mandatoryOvertime',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['OTRJobOptions.payStructure', HOURLY],
        },
        overtimeAfter8HoursDay: {
          isNested: true,
          placement: 'center',
          label: 'Overtime after 8-hour day',
          type: 'booleanRadio',
          name: 'OTRJobOptions.hourlyPayOptions.overtimeAfter8HoursDay',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['OTRJobOptions.payStructure', HOURLY],
        },
        overtimeAfter40HoursWeek: {
          isNested: true,
          placement: 'center',
          label: 'Overtime after 40-hour day',
          type: 'booleanRadio',
          name: 'OTRJobOptions.hourlyPayOptions.overtimeAfter40HoursWeek',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['OTRJobOptions.payStructure', HOURLY],
        },
      },
      mileagePayOptions: {
        isNested: true,
        placement: 'center',

        startingRatePerMileEntry: {
          isNested: true,
          name: 'OTRJobOptions.mileagePayOptions.entry.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Entry level: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
          dependence: ['OTRJobOptions.payStructure', MILEAGE],
        },
        avarageMilesPerWeekEntry: {
          name: 'OTRJobOptions.mileagePayOptions.entry.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Entry level: Average miles per week? ',
          dependence: ['OTRJobOptions.payStructure', MILEAGE],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['OTRJobOptions.payStructure', MILEAGE],
        },

        startingRatePerMileExp: {
          isNested: true,
          name: 'OTRJobOptions.mileagePayOptions.experienced.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
          dependence: ['OTRJobOptions.payStructure', MILEAGE],
        },
        avarageMilesPerWeekExp: {
          name: 'OTRJobOptions.mileagePayOptions.experienced.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Experienced Driver: Average miles per week? ',
          dependence: ['OTRJobOptions.payStructure', MILEAGE],
        },

      },
      percentageOfLoadPayOptions: {
        isNested: true,
        placement: 'center',

        driversPayPercentageEntry: {
          isNested: true,
          placement: 'center',
          name: 'OTRJobOptions.percentageOfLoadPayOptions.entry.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Entry level: Driver is paid',
          labelEnd: '% of the load',
          dependence: ['OTRJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },
        driversExpectedPayEntry: {
          name: 'OTRJobOptions.percentageOfLoadPayOptions.entry.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Entry level: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
          dependence: ['OTRJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['OTRJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        driversPayPercentageExp: {
          isNested: true,
          placement: 'center',
          name: 'OTRJobOptions.percentageOfLoadPayOptions.experienced.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Experienced Driver: Driver is paid',
          labelEnd: '% of the load',
          dependence: ['OTRJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },
        driversExpectedPayExp: {
          name: 'OTRJobOptions.percentageOfLoadPayOptions.experienced.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Experienced Driver: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
          dependence: ['OTRJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },


      },
    },
    teamDrivingJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', TEAM_DRIVING],
      fullTime: {
        isNested: true,
        placement: 'center',
        label: 'Full Time?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.fullTime',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      yearRoundWork: {
        isNested: true,
        placement: 'center',
        label: 'Year-round work?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.yearRoundWork',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      payStructure: {
        isNested: true,
        placement: 'center',
        horizontal: true,
        label: 'Pay structure',
        type: 'radio',
        name: 'teamDrivingJobOptions.payStructure',
        options: [
          { value: HOURLY, title: HOURLY },
          { value: MILEAGE, title: MILEAGE },
          { value: PERCENTAGE_OF_LOAD, title: '% of load' },
        ],
        impact: [
          { if: HOURLY, field: 'teamDrivingJobOptions.hourlyPayOptions', value: createJobGroupInitialValues[4].teamDrivingJobOptions.hourlyPayOptions },
          { if: HOURLY, field: 'teamDrivingJobOptions.mileagePayOptions', value: null },
          { if: HOURLY, field: 'teamDrivingJobOptions.percentageOfLoadPayOptions', value: null },

          { if: MILEAGE, field: 'teamDrivingJobOptions.hourlyPayOptions', value: null },
          { if: MILEAGE, field: 'teamDrivingJobOptions.mileagePayOptions', value: createJobGroupInitialValues[4].teamDrivingJobOptions.mileagePayOptions },
          { if: MILEAGE, field: 'teamDrivingJobOptions.percentageOfLoadPayOptions', value: null },

          { if: PERCENTAGE_OF_LOAD, field: 'teamDrivingJobOptions.hourlyPayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'teamDrivingJobOptions.mileagePayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'teamDrivingJobOptions.percentageOfLoadPayOptions', value: createJobGroupInitialValues[4].teamDrivingJobOptions.percentageOfLoadPayOptions },
        ],
      },
      hourlyPayOptions: {
        isNested: true,
        placement: 'center',

        startingPayEntry: {
          isNested: true,
          name: 'teamDrivingJobOptions.hourlyPayOptions.entry.startingPay',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Entry level: Starting hourly rate',
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
          className: 'input--no-arrows',
          placeholder: '0.00',
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
        },

        startingPayExp: {
          isNested: true,
          name: 'teamDrivingJobOptions.hourlyPayOptions.experienced.startingPay',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting hourly rate',
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
          className: 'input--no-arrows',
          placeholder: '0.00',
        },

        numberOfHoursPerWeek: {
          isNested: true,
          placement: 'center',
          name: 'teamDrivingJobOptions.hourlyPayOptions.numberOfHoursPerWeek',
          type: 'counter-buttons',
          min: 1,
          max: 100,
          label: 'Number of hours per week',
          placeholder: '-',
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
        },
        mandatoryOvertime: {
          isNested: true,
          placement: 'center',
          label: 'Is overtime mandatory',
          type: 'booleanRadio',
          name: 'teamDrivingJobOptions.hourlyPayOptions.mandatoryOvertime',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
        },
        overtimeAfter8HoursDay: {
          isNested: true,
          placement: 'center',
          label: 'Overtime after 8-hour day',
          type: 'booleanRadio',
          name: 'teamDrivingJobOptions.hourlyPayOptions.overtimeAfter8HoursDay',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
        },
        overtimeAfter40HoursWeek: {
          isNested: true,
          placement: 'center',
          label: 'Overtime after 40-hour day',
          type: 'booleanRadio',
          name: 'teamDrivingJobOptions.hourlyPayOptions.overtimeAfter40HoursWeek',
          options: [
            { value: true, title: 'Yes' },
            { value: false, title: 'No' },
          ],
          dependence: ['teamDrivingJobOptions.payStructure', HOURLY],
        },
      },
      mileagePayOptions: {
        isNested: true,
        placement: 'center',

        startingRatePerMileEntry: {
          isNested: true,
          name: 'teamDrivingJobOptions.mileagePayOptions.entry.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Entry level: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
          dependence: ['teamDrivingJobOptions.payStructure', MILEAGE],
        },
        avarageMilesPerWeekEntry: {
          name: 'teamDrivingJobOptions.mileagePayOptions.entry.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Entry level: Average miles per week? ',
          dependence: ['teamDrivingJobOptions.payStructure', MILEAGE],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['teamDrivingJobOptions.payStructure', MILEAGE],
        },

        startingRatePerMileExp: {
          isNested: true,
          name: 'teamDrivingJobOptions.mileagePayOptions.experienced.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
          dependence: ['teamDrivingJobOptions.payStructure', MILEAGE],
        },
        avarageMilesPerWeekExp: {
          name: 'teamDrivingJobOptions.mileagePayOptions.experienced.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Experienced Driver: Average miles per week? ',
          dependence: ['teamDrivingJobOptions.payStructure', MILEAGE],
        },

      },
      percentageOfLoadPayOptions: {
        isNested: true,
        placement: 'center',

        driversPayPercentageEntry: {
          isNested: true,
          placement: 'center',
          name: 'teamDrivingJobOptions.percentageOfLoadPayOptions.entry.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Entry level: Driver is paid',
          labelEnd: '% of the load',
          dependence: ['teamDrivingJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },
        driversExpectedPayEntry: {
          name: 'teamDrivingJobOptions.percentageOfLoadPayOptions.entry.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Entry level: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
          dependence: ['teamDrivingJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['teamDrivingJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        driversPayPercentageExp: {
          isNested: true,
          placement: 'center',
          name: 'teamDrivingJobOptions.percentageOfLoadPayOptions.experienced.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Experienced Driver: Driver is paid',
          labelEnd: '% of the load',
          dependence: ['teamDrivingJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },
        driversExpectedPayExp: {
          name: 'teamDrivingJobOptions.percentageOfLoadPayOptions.experienced.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Experienced Driver: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
          dependence: ['teamDrivingJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },
      },
    },
  },
  {
    jobLocationType: {
      isNested: true,
      customKey: nanoid(),
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
      disabled: true,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[5].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[5].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[5].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[5].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),

      payStructure: {
        isNested: true,
        label: 'Pay structure',
        type: 'radio',
        placement: 'center',
        horizontal: true,
        name: 'localJobOptions.payStructure',
        options: [
          { value: HOURLY, title: HOURLY },
          { value: MILEAGE, title: MILEAGE },
          { value: PERCENTAGE_OF_LOAD, title: '% of load' },
        ],
        impact: [
          { if: HOURLY, field: 'localJobOptions.hourlyPayOptions', value: createJobGroupInitialValues[5].localJobOptions.hourlyPayOptions },
          { if: HOURLY, field: 'localJobOptions.mileagePayOptions', value: null },
          { if: HOURLY, field: 'localJobOptions.percentageOfLoadPayOptions', value: null },

          { if: MILEAGE, field: 'localJobOptions.hourlyPayOptions', value: null },
          { if: MILEAGE, field: 'localJobOptions.mileagePayOptions', value: createJobGroupInitialValues[5].localJobOptions.mileagePayOptions },
          { if: MILEAGE, field: 'localJobOptions.percentageOfLoadPayOptions', value: null },

          { if: PERCENTAGE_OF_LOAD, field: 'localJobOptions.hourlyPayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'localJobOptions.mileagePayOptions', value: null },
          { if: PERCENTAGE_OF_LOAD, field: 'localJobOptions.percentageOfLoadPayOptions', value: createJobGroupInitialValues[5].localJobOptions.percentageOfLoadPayOptions },
        ],
      },
      hourlyPayOptions: {
        isNested: true,
        placement: 'center',
        dependence: ['localJobOptions.payStructure', HOURLY],

        beginnersPayEntry: {
          isNested: true,
          name: 'localJobOptions.hourlyPayOptions.entry.beginnersPay',
          type: 'price-input-range',
          className: 'input input--no-arrows',
          placement: 'nested_input',
          label: 'Entry level: Starting hourly rate',
          placeholder: '0.00',
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['localJobOptions.payStructure', HOURLY],
        },

        beginnersPayExp: {
          isNested: true,
          name: 'localJobOptions.hourlyPayOptions.experienced.beginnersPay',
          type: 'price-input-range',
          className: 'input input--no-arrows',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting hourly rate',
          placeholder: '0.00',
        },

      },
      mileagePayOptions: {
        isNested: true,
        placement: 'center',
        dependence: ['localJobOptions.payStructure', MILEAGE],

        startingRatePerMileEntry: {
          isNested: true,
          name: 'localJobOptions.mileagePayOptions.entry.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Entry level: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
        },
        avarageMilesPerWeekEntry: {
          name: 'localJobOptions.mileagePayOptions.entry.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Entry level: Average miles per week? ',
          placeholder: 'Type here...',
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['localJobOptions.payStructure', MILEAGE],
        },

        startingRatePerMileExp: {
          isNested: true,
          name: 'localJobOptions.mileagePayOptions.experienced.startingRatePerMile',
          type: 'price-input-range',
          placement: 'nested_input',
          label: 'Experienced Driver: Starting rate per mile',
          className: 'input--no-arrows',
          placeholder: '0.00',
        },
        avarageMilesPerWeekExp: {
          name: 'localJobOptions.mileagePayOptions.experienced.avarageMilesPerWeek',
          type: 'number',
          placement: 'center',
          label: 'Experienced Driver: Average miles per week? ',
          placeholder: 'Type here...',
        },
      },
      percentageOfLoadPayOptions: {
        isNested: true,
        placement: 'center',
        dependence: ['localJobOptions.payStructure', PERCENTAGE_OF_LOAD],

        driversPayPercentageEntry: {
          isNested: true,
          placement: 'center',
          name: 'localJobOptions.percentageOfLoadPayOptions.entry.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Entry level: Driver is paid',
          labelEnd: '% of the load',
        },
        driversExpectedPayEntry: {
          name: 'localJobOptions.percentageOfLoadPayOptions.entry.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Entry level: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
        },

        hrHorizontalLine: {
          isNested: true,
          type: 'hr',
          dependence: ['localJobOptions.payStructure', PERCENTAGE_OF_LOAD],
        },

        driversPayPercentageExp: {
          isNested: true,
          placement: 'center',
          name: 'localJobOptions.percentageOfLoadPayOptions.experienced.driversPayPercentage',
          type: 'number-select',
          min: 0,
          max: 100,
          labelStart: 'Experienced Driver: Driver is paid',
          labelEnd: '% of the load',
        },
        driversExpectedPayExp: {
          name: 'localJobOptions.percentageOfLoadPayOptions.experienced.driversExpectedPay',
          type: 'number',
          placement: 'nested_input',
          className: 'input--no-arrows',
          labelStart: 'Experienced Driver: Driver\'s expected pay is',
          labelEnd: '/week',
          placeholder: 'Type here',
        },

      },
    },
    regionalJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', REGIONAL],
      customKey: nanoid(),
      multiStopPay: {
        isNested: true,
        placement: 'center',
        label: 'Multi-stop Pay',
        type: 'booleanRadio',
        name: 'regionalJobOptions.multiStopPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      layoverPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay layover pay?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.layoverPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      breakdownPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay breakdown pay?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.breakdownPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      payPerDiem: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay a per diem?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.payPerDiem',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      dedicatedRoute: {
        isNested: true,
        placement: 'center',
        label: 'Is this a dedicated route?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.dedicatedRoute',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      physicalRequirements: {
        isNested: true,
        placement: 'center',
        label: 'Physical requirements of the job?',
        type: 'radio',
        name: 'regionalJobOptions.physicalRequirements',
        options: allowedValues.physicalRequirements,
      },
      percentDropAndHook: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'regionalJobOptions.percentDropAndHook',
        label: 'Loading Type',
        minLabel: '% Drop & Hook',
        maxLabel: '% Live load/unload',
      },
    },
    OTRJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', OVER_THE_ROAD],
      customKey: nanoid(),
      multiStopPay: {
        isNested: true,
        placement: 'center',
        label: 'Multi-stop Pay',
        type: 'booleanRadio',
        name: 'OTRJobOptions.multiStopPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      layoverPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay layover pay?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.layoverPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      detentionPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay detention pay?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.detentionPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      breakdownPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay breakdown pay?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.breakdownPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      payPerDiem: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay a per diem?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.payPerDiem',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      dedicatedRoute: {
        isNested: true,
        placement: 'center',
        label: 'Is this a dedicated route?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.dedicatedRoute',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      physicalRequirements: {
        isNested: true,
        placement: 'center',
        label: 'Physical requirements of the job?',
        type: 'radio',
        name: 'OTRJobOptions.physicalRequirements',
        options: allowedValues.physicalRequirements,
      },
      percentDropAndHook: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'OTRJobOptions.percentDropAndHook',
        label: 'Loading Type',
        minLabel: '% Drop & Hook',
        maxLabel: '% Live load/unload',
      },
      numberOfNightsAway: {
        isNested: true,
        placement: 'center',
        name: 'OTRJobOptions.numberOfNightsAway',
        type: 'number-select',
        labelStart: 'Number of nights away per week (average) ',
        min: 0,
        max: 7,
        placeholder: '-',
        labelEnd: ' days?',
      },
      avarageTimeAtHome: {
        isNested: true,
        placement: 'center',
        name: 'OTRJobOptions.avarageTimeAtHome',
        type: 'number-select',
        labelStart: 'On average, driver will have ',
        min: 0,
        max: 15,
        placeholder: '-',
        labelEnd: ' days of time off at home?',
      },
    },

    teamDrivingJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', TEAM_DRIVING],
      customKey: nanoid(),
      multiStopPay: {
        isNested: true,
        placement: 'center',
        label: 'Multi-stop Pay',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.multiStopPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      layoverPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay layover pay?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.layoverPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      detentionPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay detention pay?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.detentionPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      breakdownPay: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay breakdown pay?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.breakdownPay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      payPerDiem: {
        isNested: true,
        placement: 'center',
        label: 'Do you pay a per diem?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.payPerDiem',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      dedicatedRoute: {
        isNested: true,
        placement: 'center',
        label: 'Is this a dedicated route?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.dedicatedRoute',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      physicalRequirements: {
        isNested: true,
        placement: 'center',
        label: 'Physical requirements of the job?',
        type: 'radio',
        name: 'teamDrivingJobOptions.physicalRequirements',
        options: allowedValues.physicalRequirements,
      },
      percentDropAndHook: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'teamDrivingJobOptions.percentDropAndHook',
        label: 'Loading Type',
        minLabel: '% Drop & Hook',
        maxLabel: '% Live load/unload',
      },
      numberOfNightsAway: {
        isNested: true,
        placement: 'center',
        name: 'teamDrivingJobOptions.numberOfNightsAway',
        type: 'number-select',
        labelStart: 'Number of nights away per week (average) ',
        min: 0,
        max: 7,
        placeholder: '-',
        labelEnd: ' days?',
      },
      avarageTimeAtHome: {
        isNested: true,
        placement: 'center',
        name: 'teamDrivingJobOptions.avarageTimeAtHome',
        type: 'number-select',
        labelStart: 'On average, driver will have ',
        min: 0,
        max: 15,
        placeholder: '-',
        labelEnd: ' days of time off at home?',
      },
    },
  },
  {
    jobLocationType: {
      isNested: true,
      customKey: nanoid(),
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
      disabled: true,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[6].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'localJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[6].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[6].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[6].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),
      numberOfHoursPerWeek: {
        isNested: true,
        placement: 'center',
        name: 'localJobOptions.numberOfHoursPerWeek',
        type: 'counter-buttons',
        label: 'Number of hours per week',
        placeholder: '-',
        min: 0,
        max: 100,
      },
      mandatoryOvertime: {
        isNested: true,
        placement: 'center',
        label: 'Is overtime mandatory?',
        type: 'booleanRadio',
        name: 'localJobOptions.mandatoryOvertime',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      overtimeAfter8HoursDay: {
        isNested: true,
        placement: 'center',
        label: 'Overtime after 8-hour day',
        type: 'booleanRadio',
        name: 'localJobOptions.overtimeAfter8HoursDay',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      overtimeAfter40HoursWeek: {
        isNested: true,
        placement: 'center',
        label: 'Overtime after 40-hour week',
        type: 'booleanRadio',
        name: 'localJobOptions.overtimeAfter40HoursWeek',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
    },
    regionalJobOptions: {
      numberOfNightsAway: {
        isNested: true,
        placement: 'center',
        name: 'regionalJobOptions.numberOfNightsAway',
        type: 'number-select',
        labelStart: 'Number of nights away per week (average) ',
        labelEnd: '',
        min: 0,
        max: 7,
        placeholder: '-',
      },
      numberOfConsecutiveNightsAwayPerWeek: {
        isNested: true,
        placement: 'center',
        name: 'regionalJobOptions.numberOfConsecutiveNightsAwayPerWeek',
        type: 'number-select',
        labelStart: 'Number of consecutive nights away per week (average) ',
        labelEnd: '',
        min: 0,
        max: 7,
        placeholder: '-',
      },
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', REGIONAL],
      customKey: nanoid(),
      restPeriodWithSleeperBerths: {
        isNested: true,
        placement: 'center',
        label: 'Do drivers take their rest period in the truck with sleeper berths?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.restPeriodWithSleeperBerths',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'regionalJobOptions.companyProvidesMotels', value: null },
          { if: false, field: 'regionalJobOptions.companyProvidesMotels', value: '' },
        ],
      },
      companyProvidesMotels: {
        isNested: true,
        placement: 'center',
        label: 'Do you provide motel for your drivers?',
        type: 'booleanRadio',
        name: 'regionalJobOptions.companyProvidesMotels',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        dependence: ['regionalJobOptions.restPeriodWithSleeperBerths', false],
      },
      drivingStates: {
        isNested: true,
        placement: 'center',
        label: 'What states will the driver be driving in?',
        type: 'search-select',
        closeMenuOnSelect: false,
        withCheckBox: true,
        hideSelectedOptions: false,
        name: 'regionalJobOptions.drivingStates',
        options: allowedValues.states.map((s) => ({
          label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
        })),
        placeholder: 'States',
      },
      drivingPercentage: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'regionalJobOptions.drivingPercentage',
        labelStart: 'What percentage of the job is driving? ',
        minLabel: '% Driving',
        maxLabel: '% Non-driving responsibilities',
      },
    },
    OTRJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', OVER_THE_ROAD],
      customKey: nanoid(),
      restPeriodWithSleeperBerths: {
        isNested: true,
        placement: 'center',
        label: 'Do drivers take their rest period in the truck with sleeper berths?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.restPeriodWithSleeperBerths',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'OTRJobOptions.companyProvidesMotels', value: null },
          { if: false, field: 'OTRJobOptions.companyProvidesMotels', value: '' },
        ],
      },
      companyProvidesMotels: {
        isNested: true,
        placement: 'center',
        label: 'Do you provide motel for your drivers?',
        type: 'booleanRadio',
        name: 'OTRJobOptions.companyProvidesMotels',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        dependence: ['OTRJobOptions.restPeriodWithSleeperBerths', false],
      },
      drivingStates: {
        isNested: true,
        placement: 'center',
        label: 'What states will the driver be driving in?',
        type: 'search-select',
        closeMenuOnSelect: false,
        withCheckBox: true,
        hideSelectedOptions: false,
        name: 'OTRJobOptions.drivingStates',
        options: allowedValues.states.map((s) => ({
          label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
        })),
        placeholder: 'States',
      },
      drivingPercentage: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'OTRJobOptions.drivingPercentage',
        labelStart: 'What percentage of the job is driving? ',
        minLabel: '% Driving',
        maxLabel: '% Non-driving responsibilities',
      },
    },
    teamDrivingJobOptions: {
      isNested: true,
      placement: 'center',
      dependence: ['jobLocationType', TEAM_DRIVING],
      customKey: nanoid(),
      restPeriodWithSleeperBerths: {
        isNested: true,
        placement: 'center',
        label: 'Do drivers take their rest period in the truck with sleeper berths?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.restPeriodWithSleeperBerths',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        impact: [
          { if: true, field: 'teamDrivingJobOptions.companyProvidesMotels', value: null },
          { if: false, field: 'teamDrivingJobOptions.companyProvidesMotels', value: '' },
        ],
      },
      companyProvidesMotels: {
        isNested: true,
        placement: 'center',
        label: 'Do you provide motel for your drivers?',
        type: 'booleanRadio',
        name: 'teamDrivingJobOptions.companyProvidesMotels',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
        dependence: ['teamDrivingJobOptions.restPeriodWithSleeperBerths', false],
      },
      drivingStates: {
        isNested: true,
        placement: 'center',
        label: 'What states will the driver be driving in?',
        type: 'search-select',
        closeMenuOnSelect: false,
        withCheckBox: true,
        hideSelectedOptions: false,
        name: 'teamDrivingJobOptions.drivingStates',
        options: allowedValues.states.map((s) => ({
          label: s.name, value: s.name, matches: [s.name, s.abr], ...s,
        })),
        placeholder: 'States',
      },
      drivingPercentage: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'teamDrivingJobOptions.drivingPercentage',
        labelStart: 'What percentage of the job is driving? ',
        minLabel: '% Driving',
        maxLabel: '% Non-driving responsibilities',
      },
    },
  },
  {
    jobLocationType: {
      isNested: true,
      customKey: nanoid(),
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
      disabled: true,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[7].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'localJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[7].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[7].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[7].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),
      workDaysVary: {
        isNested: true,
        placement: 'center',
        label: 'Do the work days vary?',
        type: 'booleanRadio',
        name: 'localJobOptions.workDaysVary',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      workDays: {
        isNested: true,
        placement: 'center',
        label: 'Workdays',
        type: 'checkbox',
        name: 'localJobOptions.workDays',
        options: allowedValues.workDays,
      },
      workHoursVary: {
        isNested: true,
        placement: 'center',
        label: 'Do the work hours vary?',
        type: 'booleanRadio',
        name: 'localJobOptions.workHoursVary',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      workHoursType: {
        isNested: true,
        placement: 'center',
        horizontal: true,
        label: 'Work Hours (select closest option)',
        type: 'radio',
        name: 'localJobOptions.workHoursType',
        options: allowedValues.workHours,
      },
    },
    OTRJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', OVER_THE_ROAD],
      customKey: nanoid(),
      jobsBoards: {
        isNested: true,
        type: 'job-boards',
      },
    },
    teamDrivingJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', TEAM_DRIVING],
      customKey: nanoid(),
      jobsBoards: {
        isNested: true,
        type: 'job-boards',
      },
    },
    regionalJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', REGIONAL],
      customKey: nanoid(),
      jobsBoards: {
        isNested: true,
        type: 'job-boards',
      },
    },
  },
  {
    jobLocationType: {
      isNested: true,
      customKey: nanoid(),
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
      disabled: true,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[8].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'localJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[8].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[8].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[8].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),
      physicalRequirements: {
        isNested: true,
        placement: 'center',
        label: 'Physical requirements of the job?',
        type: 'radio',
        name: 'localJobOptions.physicalRequirements',
        options: allowedValues.physicalRequirements,
      },
      requiredLoadUnload: {
        isNested: true,
        placement: 'center',
        label: 'Is the driver required to load and/or unload freight?',
        type: 'booleanRadio',
        name: 'localJobOptions.requiredLoadUnload',
        options: [
          { value: true, title: 'Yes' },
          { value: false, title: 'No' },
        ],
      },
      drivingPercentage: {
        isNested: true,
        horizontal: true,
        placement: 'center',
        type: 'range',
        name: 'localJobOptions.drivingPercentage',
        labelStart: 'What percentage of the job is driving? ',
        minLabel: '% Driving',
        maxLabel: '% Non-driving responsibilities',
      },
    },
    OTRJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', OVER_THE_ROAD],
      customKey: nanoid(),
      paymentMethod: {
        label: 'Payment method',
        isNested: true,
        type: 'payment-methods',
        placement: 'center',
      },
      autoRenew: {
        label: 'This job will be posted on the selected job boards for 28 days. If you would like this job to automatically renew (and charge your payment method again) to the selected job boards at the end of 28 days, check the “auto renew” box below.',
        isNested: true,
        type: 'booleanCheckbox',
        name: 'autoRenew',
        placement: 'center',
      },

      hrHorizontalLine: {
        isNested: true,
        label: '',
        type: 'info-block',
        placement: 'center',
        dependence: ['jobLocationType', OVER_THE_ROAD],
      },
    },
    teamDrivingJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', TEAM_DRIVING],
      customKey: nanoid(),
      paymentMethod: {
        label: 'Payment method',
        isNested: true,
        type: 'payment-methods',
        placement: 'center',
      },
      autoRenew: {
        label: 'This job will be posted on the selected job boards for 28 days. If you would like this job to automatically renew (and charge your payment method again) to the selected job boards at the end of 28 days, check the “auto renew” box below.',
        isNested: true,
        type: 'booleanCheckbox',
        name: 'autoRenew',
        placement: 'center',
      },

      hrHorizontalLine: {
        isNested: true,
        label: '',
        type: 'info-block',
        placement: 'center',
        dependence: ['jobLocationType', TEAM_DRIVING],
      },
    },
    regionalJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', REGIONAL],
      customKey: nanoid(),
      paymentMethod: {
        label: 'Payment method',
        isNested: true,
        type: 'payment-methods',
        placement: 'center',
      },
      autoRenew: {
        label: 'This job will be posted on the selected job boards for 28 days. If you would like this job to automatically renew (and charge your payment method again) to the selected job boards at the end of 28 days, check the “auto renew” box below.',
        isNested: true,
        type: 'booleanCheckbox',
        name: 'autoRenew',
        placement: 'center',
      },

      hrHorizontalLine: {
        isNested: true,
        label: '',
        type: 'info-block',
        placement: 'center',
        dependence: ['jobLocationType', REGIONAL],
      },
    },
  },
  {
    jobLocationType: {
      isNested: true,
      customKey: nanoid(),
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
      disabled: true,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[9].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'localJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[9].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[9].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[9].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),
      jobsBoards: {
        isNested: true,
        type: 'job-boards',
      },
    },
  },
  {
    jobLocationType: {
      isNested: true,
      customKey: nanoid(),
      label: 'Job type',
      type: 'radio',
      horizontal: true,
      name: 'jobLocationType',
      options: allowedValues.jobLocationType,
      disabled: true,
      impact: [
        { if: LOCAL, field: 'localJobOptions', value: createJobGroupInitialValues[9].localJobOptions },
        { if: REGIONAL, field: 'localJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'localJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'localJobOptions', value: null },

        { if: LOCAL, field: 'regionalJobOptions', value: null },
        { if: REGIONAL, field: 'regionalJobOptions', value: createJobGroupInitialValues[9].regionalJobOptions },
        { if: OVER_THE_ROAD, field: 'regionalJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'regionalJobOptions', value: null },

        { if: LOCAL, field: 'OTRJobOptions', value: null },
        { if: REGIONAL, field: 'OTRJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'OTRJobOptions', value: createJobGroupInitialValues[9].OTRJobOptions },
        { if: TEAM_DRIVING, field: 'OTRJobOptions', value: null },

        { if: LOCAL, field: 'teamDrivingJobOptions', value: null },
        { if: REGIONAL, field: 'teamDrivingJobOptions', value: null },
        { if: OVER_THE_ROAD, field: 'teamDrivingJobOptions', value: null },
        { if: TEAM_DRIVING, field: 'teamDrivingJobOptions', value: createJobGroupInitialValues[9].teamDrivingJobOptions },
      ],
    },
    localJobOptions: {
      isNested: true,
      dependence: ['jobLocationType', LOCAL],
      customKey: nanoid(),
      paymentMethod: {
        label: 'Payment method',
        isNested: true,
        type: 'payment-methods',
        placement: 'center',
      },
      autoRenew: {
        label: 'This job will be posted on the selected job boards for 28 days. If you would like this job to automatically renew (and charge your payment method again) to the selected job boards at the end of 28 days, check the “auto renew” box below.',
        isNested: true,
        type: 'booleanCheckbox',
        name: 'autoRenew',
        placement: 'center',
      },

      hrHorizontalLine: {
        isNested: true,
        label: '',
        type: 'info-block',
        placement: 'center',
        dependence: ['jobLocationType', LOCAL],
      },
    },
  },
]

export const createJobGroupConfig = createJobGroupInputs.map((pageInputs, i) => ({
  inputs: pageInputs,
  initialValues: cloneDeep(createJobGroupInitialValues[i]),
  schema: jobSchema.createJobGroup.front[i],
}))
