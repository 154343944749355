/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'

import './styles/index.scss'
import 'react-notifications/lib/notifications.css'
import ReactGA from 'react-ga4'
import App from './pages/_app'

import { ENV } from './config'

if (ENV === 'production') {
  console.log('Tag activating')
  ReactGA.initialize('G-L00Q93QS9F')
  ReactGA.send('pageview')
  console.log('Tag active')
} else {
  console.log('Tag should not be activated here')
}

const app = (
  <RecoilRoot>
    <App />
  </RecoilRoot>
)

ReactDOM.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>,
  document.getElementById('root'),
)
