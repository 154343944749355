import { sendRequest } from './api'
import {BODY_TYPES} from "../config";
/* eslint-disable import/prefer-default-export */
export const createApplicationForm = (id, data) => (
  sendRequest('POST', `/applications/from/jobs/${id}`, { body: data })
)

export const saveDriverApplicationSettings = (id, data, token) => (
    sendRequest('POST', `/applications/driver/${id}/settings/`, {
        body: data,
        headers: token ? { authorization: `Bearer ${token}` } : null
    })
)

export const getApplicationList = (query, token) => (
    sendRequest('GET', '/applications', { query, headers: token ? { authorization: `Bearer ${token}` } : null })
)

export const approveApplication = (id, companyId, token) => (
    sendRequest('POST', `/applications/${id}/company/${companyId}/approve`, {headers: token ? { authorization: `Bearer ${token}` } : null})
)

export const declineApplication = (id, companyId, token) => (
    sendRequest('POST', `/applications/${id}/company/${companyId}/decline`, {headers: token ? { authorization: `Bearer ${token}` } : null})
)

export const getApplicationPdf = (id, token) => (
    sendRequest('GET', `/applications/${id}/pdf`,
        {
            headers: token ? {
                'Accept': 'application/pdf',
                'Content-Type': 'application/pdf',
                'Authorization': `Bearer ${token}`,
                'Response-Type': 'arraybuffer'
            } : null,
            raw: true
        })
)