import { recruiterSchema } from '@job-board/job-board-validation'

import { signUpRecruiterFormConfig } from './signUp'

const inputs = {
  name: signUpRecruiterFormConfig.inputs.name,
  phone: signUpRecruiterFormConfig.inputs.phone,
  password: signUpRecruiterFormConfig.inputs.password,
}

const initialValues = {
  name: '',
  phone: '',
  password: '',
}

const schema = recruiterSchema.createInvitedRecruiter.front
/* eslint-disable import/prefer-default-export */
export const signUpInvitedRecruiterFormConfig = {
  schema,
  initialValues,
  inputs,
}
