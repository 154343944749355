import jwt from 'jsonwebtoken'

import { ROLES } from '../../config'
import {
  getAdmin,
  getRecruiter,
  getRepresentative,
  getRepresentativeSchoolList,
  getSchool,
  handleRequestError,
} from '../../api'
import {getStudent} from "../../api/student";

export default async ({
  token,
  currentSchool = null,
  setToken,
  setRoleInfo,
  setRecruiterStateInfo,
  setAdminStateInfo,
  setRepresentativeStateInfo,
  setCurrentSchoolStateInfo,
  setStudent,
}) => {
  const clearUserState = () => {
    setRoleInfo(null)
    setRecruiterStateInfo(null)
    setRepresentativeStateInfo(null)
    setCurrentSchoolStateInfo(null)
    setAdminStateInfo(null)
    setToken(null)
  }

  const clearUserStateAndHandleRequestError = (err) => {
    clearUserState()
    handleRequestError(err)
  }

  let decodedToken
  if (token) {
    const newDecodedToken = jwt.decode(token)
    if (newDecodedToken?.role && newDecodedToken.exp > (Date.now() / 1000)) {
      decodedToken = newDecodedToken
      setRoleInfo(newDecodedToken.role)
    } else {
      clearUserState()
    }
  }
  if (decodedToken) {
    const { role, id } = decodedToken
    try {
      if (role === ROLES.RECRUITER) {
        const { data: newRecruiterInfo } = await getRecruiter(id, token)
        setRepresentativeStateInfo(null)
        setCurrentSchoolStateInfo(null)
        setAdminStateInfo(null)
        setRecruiterStateInfo(newRecruiterInfo)
      } else if (role === ROLES.REPRESENTATIVE) {
        const { data: newRepresentativeInfo } = await getRepresentative(id, token)
        setRecruiterStateInfo(null)
        setAdminStateInfo(null)
        setRepresentativeStateInfo(newRepresentativeInfo)

        if (currentSchool?.id) {
          const { data: newSchoolInfo } = await getSchool(currentSchool?.id, token)
          setCurrentSchoolStateInfo(newSchoolInfo)
          return { currentSchool: newSchoolInfo }
        }
        const { data: { rows: [newSchoolInfo] } } = await getRepresentativeSchoolList(token, id)
        setCurrentSchoolStateInfo(newSchoolInfo)
        return { currentSchool: newSchoolInfo }
      } else if (role === ROLES.ADMIN) {
        const { data: newAdminInfo } = await getAdmin(id, token)
        setRecruiterStateInfo(null)
        setRepresentativeStateInfo(null)
        setCurrentSchoolStateInfo(null)
        setAdminStateInfo(newAdminInfo)

      } else if (role === ROLES.STUDENT) {
        const { data: currentStudent } = await getStudent(id, token)
        setRecruiterStateInfo(null)
        setRepresentativeStateInfo(null)
        setCurrentSchoolStateInfo(null)
        setAdminStateInfo(null)
        setStudent(currentStudent)

        return { currentStudent }
      }
    } catch (err) {
      clearUserStateAndHandleRequestError(err)
    }
  }
  return {}
}
