import { DOMAIN } from '../config'

const getDomainInfo = (host) => {
  const domain = host
  const subdomain = domain.split(DOMAIN)[0].slice(0, -1)

  return { domain, subdomain }
}

export default getDomainInfo
