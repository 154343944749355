import { authSchema } from '@job-board/job-board-validation'

const initialValues = {
  email: '',
  password: '',
}

const inputs = {
  email: {
    type: 'email',
    className: 'input',
    placeholder: 'E-mail',
  },
  password: {
    type: 'password',
    className: 'input',
    placeholder: 'Password',
  },
}

const schema = authSchema.login.front
/* eslint-disable import/prefer-default-export */
export const loginRecruiterConfig = {
  initialValues,
  schema,
  inputs,
}
