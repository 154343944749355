import { recruiterSchema } from '@job-board/job-board-validation'

import { signUpRecruiterFormConfig } from './signUp'

const inputs = {
  name: signUpRecruiterFormConfig.inputs.name,
  email: signUpRecruiterFormConfig.inputs.email,
  phone: signUpRecruiterFormConfig.inputs.phone,
  password: signUpRecruiterFormConfig.inputs.password
}

const initialValues = {
  name: '',
  email: '',
  phone: '',
  password: ''
}

const schema = recruiterSchema.patchRecruiter.front
/* eslint-disable import/prefer-default-export */
export const patchRecruiterConfig = {
  schema,
  initialValues,
  inputs,
}
